import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth';
import {
  fetchUserSuccess,
  fetchUserFailure,
  fetchUserStart,
  signInWithGoogleStart,
  signInWithGoogleSuccess,
  signInWithGoogleFailure
} from './actions';
import { auth, googleProvider, signInAnon } from '../../firebase/auth';
import { getByPath } from '../../firebase/database';
import { addDocByCollectionPath, queryDocsByCollectionPath } from '../../firebase/firestore';

export const fetchUserAsync = (userId) => async (dispatch) => {
  try {
    dispatch(fetchUserStart());
    const resp = await getByPath(`/users/${userId}`);
    dispatch(fetchUserSuccess(resp));
  } catch (error) {
    dispatch(fetchUserFailure(error.message));
  }
};

export const signInAnonAsync = () => async (dispatch) => {
  try {
    dispatch(fetchUserStart());
    const resp = await signInAnon();
    dispatch(fetchUserSuccess(resp.user));
  } catch (error) {
    dispatch(fetchUserFailure(error.message));
  }
};

export const signInWithGoogle = () => async (dispatch) => {
  try {
    dispatch(signInWithGoogleStart());
    const res = await signInWithPopup(auth, googleProvider);
    const { user } = res;
    const querySnapshot = await queryDocsByCollectionPath('users', [
      ['where', 'uid', '==', user.uid]
    ]);
    console.log(querySnapshot.docs);
    if (querySnapshot.docs.length === 0) {
      const userData = {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
        emailVerified: user.emailVerified
      };
      await addDocByCollectionPath('users', userData);
    }

    const userObj = {
      id: querySnapshot.docs[0].id,
      ...querySnapshot.docs[0].data()
    };
    dispatch(signInWithGoogleSuccess(userObj));
  } catch (err) {
    console.error(err);
    dispatch(signInWithGoogleFailure());
  }
};

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = res;
    const userData = {
      uid: user.uid,
      name,
      authProvider: 'local',
      email
    };
    await addDocByCollectionPath('users', userData);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const logout = () => {
  signOut(auth);
};

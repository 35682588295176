import { memo, useState } from 'react';
import { CylinderBufferGeometry } from 'three';

function Cylinde({ position, scale, rotation, texture, handleClick, ...props }) {
  const { cylinderBufferGeometry, meshStandardMaterial, cylindePhysics } = props;
  const [hover, setHover] = useState(null);

  return (
    <mesh
      castShadow
      position={position}
      scale={[0.1, 0.1, 0.1]}
      rotation={rotation}
      onPointerMove={(e) => {
        e.stopPropagation();
        setHover(Math.floor(e.faceIndex / 2));
      }}
      onPointerOut={() => {
        setHover(null);
      }}>
      <cylinderBufferGeometry attach='geometry' args={props.args} />
      <meshStandardMaterial attach='material' color={props.color} />
    </mesh>
  );
}

function equalProps(prevProps, nextProps) {
  const equalPosition =
    prevProps.position[0] === nextProps.position[0] &&
    prevProps.position[1] === nextProps.position[1] &&
    prevProps.position[2] === nextProps.position[2];

  return equalPosition && prevProps.texture === nextProps.texture;
}

export default memo(Cylinde, equalProps);

import { useBox } from '@react-three/cannon';
import { useFrame } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { Vector3 } from 'three';
import * as THREE from 'three';

function Box({ position, args, direction, active, isTopItem, setTopLayerPosition, scale }) {
  const [ref, api] = useBox(() => ({
    position,
    mass: 0,
    type: active ? 'Dynamic' : 'Static',
    scale
  }));

  const velocity = useRef(pos);

  useEffect(() => {
    api.velocity.subscribe((v) => {
      velocity.current = v;
    });
  }, [ref, api.velocity]);

  //   console.log(pos[1], active);
  useFrame(() => {
    if (active) {
      const changeRate = direction === 'x' ? new Vector3(1, 0, 0) : new Vector3(0, 0, 1);

      changeRate.normalize().multiplyScalar(4);

      if (direction === 'x') {
        api.velocity.set(changeRate.x, 0, velocity.current[2]);
        // console.log("xx");
      } else {
        api.velocity.set(velocity.current[0], 0, changeRate.z);
        // console.log("zz");
      }

      ref.current.getWorldPosition(ref.current.position);
      //   console.log(isTopItem, ref.current.position);
      if (isTopItem) {
        setTopLayerPosition(ref.current.position);
      }
      // if (checkLayer) {
      //   setTopLayerPosition(ref.current.position);
      //   // console.log("topLayerpos", topLayerPosition);
      // }

      // console.log(checkLayer);
    } else {
      api.velocity.set(0, 0, 0);
    }
  });

  // useEffect(() => {
  //   console.log(checkLayer);
  //   if (checkLayer) {
  //     document.querySelector("canvas").addEventListener("click", () => {
  //       setPreLayerPosition(ref.current.position);
  //       console.log("pre", ref.current.position);
  //       setCheckLayer(false);
  //     });
  //   }
  // }, []);

  const color = new THREE.Color(`hsl(${8 + pos[1] * 4}, 100%, 45%)`);

  return (
    <mesh ref={ref} position={pos} scale={scale}>
      <boxGeometry attach='geometry' args={args} />
      <meshLambertMaterial attach='material' color={color} />
    </mesh>
  );
}

export default Box;

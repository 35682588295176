import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';

function Submarine({ modelUrl, ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(modelUrl);
  return (
    <group ref={group} {...props} dispose={null}>
      {Object.keys(nodes).map((key) => (
        <mesh
          key={key}
          geometry={nodes[key].geometry}
          material={nodes[key].material}
          position={nodes[key].position}
          material-color='#ff0000'
          material-opacity={1}
          material-transparent
        />
      ))}
    </group>
  );
}

export default Submarine;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Hero() {
  const navigate = useNavigate();

  const [meetingId, setMeetingId] = useState();

  const handleJoin = (e) => {
    e.preventDefault();
    if (!meetingId) {
      // alert('invalid id');
      return;
    }
    navigate(`/meeting/${meetingId}`);
  };

  const handleNewMeeting = () => {
    navigate('/meeting/new');
  };

  const renderPattern = (
    <div className='hidden lg:block lg:absolute lg:inset-0' aria-hidden='true'>
      <svg
        className='absolute top-0 left-1/2 transform translate-x-64 -translate-y-8'
        width={640}
        height={784}
        fill='none'
        viewBox='0 0 640 784'>
        <defs>
          <pattern
            id='9ebea6f4-a1f5-4d96-8c4e-4c2abf658047'
            x={118}
            y={0}
            width={20}
            height={20}
            patternUnits='userSpaceOnUse'>
            <rect x={0} y={0} width={4} height={4} className='text-gray-200' fill='currentColor' />
          </pattern>
        </defs>
        <rect y={72} width={640} height={640} className='text-gray-50' fill='currentColor' />
        <rect x={118} width={404} height={784} fill='url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)' />
      </svg>
    </div>
  );

  const renderForm = (
    <div className='sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left'>
      <h1>
        <span className='mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl'>
          <span className='block text-gray-900'>Evasis</span>
          <span className='block text-indigo-600'>discover our metaworld</span>
        </span>
      </h1>

      <div className='mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0'>
        <p className='text-base font-medium text-gray-900'>Join or create a meeting</p>
        <form onSubmit={handleJoin} className='mt-3 sm:flex'>
          <div>
            {/* eslint-disable-next-line */}
            <label htmlFor='meetingId' className='sr-only'>
              Meeting ID
            </label>
            <input
              type='text'
              name='meetingId'
              id='meetingId'
              value={meetingId}
              onChange={(e) => setMeetingId(e.target.value)}
              className='block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300'
              placeholder='Enter meeting ID'
            />
          </div>

          <div className='mt-4 sm:mt-0 sm:ml-3'>
            <button
              disabled={!meetingId}
              onClick={handleJoin}
              type='submit'
              className={
                meetingId
                  ? 'inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  : 'inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white'
              }
              // className="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-600 text-base font-medium text-white shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-10"
            >
              Join
            </button>
          </div>

          <div className='mt-4 sm:mt-0 sm:ml-3'>
            <button
              type='button'
              onClick={handleNewMeeting}
              className='block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-600 text-base font-medium text-white shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 sm:px-10'>
              New meeting
            </button>
          </div>
        </form>
        <p className='mt-3 text-sm text-gray-500'>
          We care about the protection of your data. Read our
          {/* <a href="#" className="font-medium text-gray-900 underline"> */}
          Privacy Policy
          {/* </a> */}.
        </p>
      </div>
    </div>
  );

  const renderVideoArea = (
    <div className='mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center'>
      <svg
        className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden'
        width={640}
        height={784}
        fill='none'
        viewBox='0 0 640 784'
        aria-hidden='true'>
        <defs>
          <pattern
            id='4f4f415c-a0e9-44c2-9601-6ded5a34a13e'
            x={118}
            y={0}
            width={20}
            height={20}
            patternUnits='userSpaceOnUse'>
            <rect x={0} y={0} width={4} height={4} className='text-gray-200' fill='currentColor' />
          </pattern>
        </defs>
        <rect y={72} width={640} height={640} className='text-gray-50' fill='currentColor' />
        <rect x={118} width={404} height={784} fill='url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)' />
      </svg>
      <div className='relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md'>
        <button
          type='button'
          className='relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
          <span className='sr-only'>Watch our video to learn more</span>
          <img
            className='w-full'
            src='https://images.unsplash.com/photo-1556740758-90de374c12ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            alt=''
          />
          <div
            className='absolute inset-0 w-full h-full flex items-center justify-center'
            aria-hidden='true'>
            <svg className='h-20 w-20 text-indigo-500' fill='currentColor' viewBox='0 0 84 84'>
              <circle opacity='0.9' cx={42} cy={42} r={42} fill='white' />
              <path d='M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z' />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );

  return (
    <div className='relative bg-white overflow-hidden'>
      {renderPattern}
      <div className='relative pb-16 sm:pb-24 lg:pb-32'>
        <main className='mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32'>
          <div className='lg:grid lg:grid-cols-12 lg:gap-8'>
            {renderForm}

            {renderVideoArea}
          </div>
        </main>
      </div>
    </div>
  );
}

import React, { useState, memo } from 'react';
import { useFrame } from '@react-three/fiber';
import { useBox } from '@react-three/cannon';
import * as textures from '../textures';
import AxesHelper from '../axesHelper';

function Cube({ position, scale, rotation, texture, handleClick, ...props }) {
  const { boxBufferGeometry, meshStandardMaterial, colorCube, cubePhysics, axHelper } = props;
  const [hover, setHover] = useState(null);
  const [ref, api] = useBox(() => ({
    type: cubePhysics.type,
    position,
    scale,
    rotation
  }));
  const color = texture === 'glass' ? colorCube.ifGlass : colorCube.else;

  useFrame(() => {
    api.position.set(...position);
  });

  return (
    <>
      {axHelper && <AxesHelper />}
      <mesh
        castShadow
        ref={ref}
        scale={scale}
        // position={position}
        rotation={rotation}
        onPointerMove={(e) => {
          e.stopPropagation();
          setHover(Math.floor(e.faceIndex / 2));
        }}
        onPointerOut={() => {
          setHover(null);
        }}>
        <boxBufferGeometry args={boxBufferGeometry.args} attach={boxBufferGeometry.attach} />
        <meshStandardMaterial
          attach={meshStandardMaterial.attach}
          map={textures[texture]}
          color={hover != null ? meshStandardMaterial.hoverColor : color}
          opacity={texture === 'glass' ? meshStandardMaterial.opacityGlass : 1}
          transparent={meshStandardMaterial.transparent}
          wireframe={meshStandardMaterial.wireframe}
        />
      </mesh>
    </>
  );
}

function equalProps(prevProps, nextProps) {
  const equalPosition =
    prevProps.position[0] === nextProps.position[0] &&
    prevProps.position[1] === nextProps.position[1] &&
    prevProps.position[2] === nextProps.position[2];

  return equalPosition && prevProps.texture === nextProps.texture;
}

export default memo(Cube, equalProps);

import {
  PencilAltIcon,
  PhotographIcon,
  PlayIcon,
  PuzzleIcon,
  SearchIcon
} from '@heroicons/react/solid';
import { useEffect, useState } from 'react';

import ColorPicker from '../../form/ColorPicker';

const tabs = [
  { name: 'Skin Color', key: 'skinColor', icon: PhotographIcon, current: true },
  { name: 'Face', key: 'face', icon: PlayIcon },
  { name: 'Clothing', key: 'clothing', icon: PuzzleIcon }
];
const colors = ['#FFCCCC', '#FFFFCC', '#CCFFCC', '#CCFFFF', '#CCCCFF', '#E0E0E0'];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Tabs({ color, setColor }) {
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);

  useEffect(() => {
    switch (selectedTab) {
      case 'Skin color':
        break;
      case 'Face':
        break;
      case 'Clothing':
        break;
      default:
    }
  }, [selectedTab]);

  const renderContent = () => {
    switch (selectedTab) {
      case 'skinColor':
        return (
          <ColorPicker
            label=''
            name='skin.color'
            options={colors}
            selectedColor={color}
            setSelectedColor={setColor}
          />
        );
      case 'face':
        return null;
      case 'clothing':
        return null;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className='sm:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id='tabs'
          name='tabs'
          className='block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
          defaultValue={tabs.find((tab) => selectedTab === tab.key).name}>
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {tabs.map((tab) => (
              <button
                onClick={() => setSelectedTab(tab.key)}
                type='button'
                key={tab.name}
                className={classNames(
                  tab.key === selectedTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.key === selectedTab ? 'page' : undefined}>
                <tab.icon
                  className={classNames(
                    tab.key === selectedTab
                      ? 'text-indigo-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden='true'
                />
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className='pt-4'>
        <ul className='grid grid-cols-3 gap-6'>{renderContent()}</ul>
      </div>
    </div>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function PrivateRoute({ redirectPath = '/login', children }) {
  const location = useLocation();
  const user = useSelector((st) => st.auth.user.data);

  if (!user || user.isAnonymous) {
    return <Navigate to={redirectPath} state={{ from: location.pathname }} replace />;
  }

  return children || <Outlet />;
}

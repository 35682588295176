/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { Vector3 } from 'three';
import { useFrame } from '@react-three/fiber';
import FPVOrbit from './FPVOrbit';
import Avatar from './Avatar';

export default function Player(props) {
  const [playerPosition, setPlayerPosition] = useState(new Vector3())
  const [velocity, setVelocity] = useState(new Vector3())
  const [direction, setDirection] = useState(new Vector3())
  const [delta, setDelta] = useState(0)

  useFrame(() => {
  })

  return (
    <>
      <FPVOrbit playerPosition={playerPosition} setPlayerPosition={setPlayerPosition} velocity={velocity} direction={direction} delta={delta} />
      <Avatar setPlayerPosition={setPlayerPosition} setVelocity={setVelocity} setDirection={setDirection} setDelta={setDelta} {...props} />
    </>
  );
}
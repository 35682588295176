import React, { useEffect, useState } from 'react';
import { usePlane } from '@react-three/cannon';
import { MeshReflectorMaterial, MeshDistortMaterial, MeshWobbleMaterial } from '@react-three/drei';
import { TextureLoader, RepeatWrapping, NearestFilter, LinearMipMapLinearFilter } from 'three';
import { MESH_COMPONENT_TYPES, PLANE_COMPONENT_TYPES } from '../../../constants';

function PlaneGeometry(props) {
  return <planeGeometry {...props} />;
}
function PlaneBufferGeometry(props) {
  return <planeBufferGeometry {...props} />;
}
function MeshStandardMaterial(props) {
  return <meshStandardMaterial {...props} />;
}

function Ground({ textureURL, position, mesh, meshMaterial, plane, onClick }) {
  const texture = new TextureLoader().load(textureURL);
  texture.magFilter = NearestFilter;
  texture.minFilter = LinearMipMapLinearFilter;
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  texture.repeat.set(100, 100);

  const [ref] = usePlane(() => ({
    rotation: mesh.rotation,
    position
  }));

  const renderPlane = () => {
    const nameToComponent = {
      [PLANE_COMPONENT_TYPES.planeGeometry]: PlaneGeometry,
      [PLANE_COMPONENT_TYPES.planeBufferGeometry]: PlaneBufferGeometry
    };
    const Component = nameToComponent[plane.componentType];
    if (!Component) return null;
    return <Component {...plane} />;
  };

  const renderMeshMaterial = () => {
    const nameToComponent = {
      [MESH_COMPONENT_TYPES.MeshReflectorMaterial]: MeshReflectorMaterial,
      [MESH_COMPONENT_TYPES.MeshDistortMaterial]: MeshDistortMaterial,
      [MESH_COMPONENT_TYPES.MeshWobbleMaterial]: MeshWobbleMaterial,
      [MESH_COMPONENT_TYPES.meshStandardMaterial]: MeshStandardMaterial
    };
    const Component = nameToComponent[meshMaterial.componentType];
    if (!Component) return null;
    return <Component {...meshMaterial} map={texture} />;
  };

  const handleClick = (e) => {
    e.stopPropagation();
    const [x, y, z] = Object.values(e.point).map((coord) => Math.ceil(coord));
    onClick(x, y, z);
  };

  return (
    <mesh ref={ref} receiveShadow onClick={onClick ? handleClick : null}>
      {renderPlane()}
      {renderMeshMaterial()}
    </mesh>
  );
}

export default Ground;

import {
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  SIGN_IN_ANON_START,
  SIGN_IN_ANON_SUCCESS,
  SIGN_IN_ANON_FAILURE,
  SIGN_IN_WITH_GOOGLE_START,
  SIGN_IN_WITH_GOOGLE_SUCCESS,
  SIGN_IN_WITH_GOOGLE_FAILURE
} from './types';

export const fetchUserStart = () => ({
  type: FETCH_USER_START
});

export const fetchUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: { user }
});

export const fetchUserFailure = (errorMessage) => ({
  type: FETCH_USER_FAILURE,
  payload: { errorMessage }
});

export const signInAnonStart = () => ({
  type: SIGN_IN_ANON_START
});

export const signInAnonSuccess = (user) => ({
  type: SIGN_IN_ANON_SUCCESS,
  payload: { user }
});

export const signInAnonFailure = (errorMessage) => ({
  type: SIGN_IN_ANON_FAILURE,
  payload: { errorMessage }
});

export const signInWithGoogleStart = () => ({
  type: SIGN_IN_WITH_GOOGLE_START
});

export const signInWithGoogleSuccess = (user) => ({
  type: SIGN_IN_WITH_GOOGLE_SUCCESS,
  payload: { user }
});

export const signInWithGoogleFailure = (errorMessage) => ({
  type: SIGN_IN_WITH_GOOGLE_FAILURE,
  payload: { errorMessage }
});

import { useSphere } from '@react-three/cannon';
import { memo, useState } from 'react';

import AxesHelper from '../axesHelper';
import * as textures from '../textures';

function Sphere({ position, scale, rotation, texture, handleClick, ...props }) {
  const { sphereBufferGeometry, material, meshStandardMaterial, spherePhysics } = props;
  const [hover, setHover] = useState(null);

  const sphereConf = {
    transform: {
      position,
      rotation: [0, 0, 0],
      scale: [1, 1, 1]
    },
    geometry: {
      args: [0.7, 30, 30]
    },
    // material: {
    //   color: 'pink',
    //   texture: null
    // },
    physics: {
      mass: 0
    },
    castShadow: false
  };

  const [ref] = useSphere(() => ({
    type: spherePhysics.type,
    position: sphereConf.transform.position,
    scale: sphereConf.transform.scale,
    rotation: sphereConf.transform.rotation,
    mass: sphereConf.physics.mass
  }));

  return (
    <mesh
      castShadow={sphereConf.castShadow}
      ref={ref}
      position={sphereConf.transform.position}
      scale={sphereConf.transform.scale}
      rotation={sphereConf.transform.rotation}
      onPointerMove={(e) => {
        e.stopPropagation();
        setHover(Math.floor(e.faceIndex / 2));
      }}
      onPointerOut={() => {
        setHover(null);
      }}>
      <sphereBufferGeometry args={sphereConf.geometry.args} attach={sphereBufferGeometry.attach} />
      <meshStandardMaterial
        // color={meshStandardMaterial.color}
        // attach={meshStandardMaterial.attach}
        {...material}
        {...meshStandardMaterial}
      />
    </mesh>
  );
}

function equalProps(prevProps, nextProps) {
  const equalPosition =
    prevProps.position.x === nextProps.position.x &&
    prevProps.position.y === nextProps.position.y &&
    prevProps.position.z === nextProps.position.z;

  return equalPosition && prevProps.texture === nextProps.texture;
}

export default memo(Sphere, equalProps);

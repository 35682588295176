import { useSphere } from '@react-three/cannon';
import { useFrame } from '@react-three/fiber';
import { memo, useState } from 'react';

function Torus({ position, scale, rotation, texture, handleClick, ...props }) {
  const { torusBufferGeometry, meshStandardMaterial, torusPhysics } = props;
  const [hover, setHover] = useState(null);

  return (
    <mesh
      castShadow
      position={position}
      scale={[0.1, 0.1, 0.1]}
      rotation={rotation}
      onPointerMove={(e) => {
        e.stopPropagation();
        setHover(Math.floor(e.faceIndex / 2));
      }}
      onPointerOut={() => {
        setHover(null);
      }}>
      <torusGeometry args={[10, 3, 16, 100]} />
      <meshStandardMaterial attach='material' color='lightblue' />
    </mesh>
  );
}

function equalProps(prevProps, nextProps) {
  const equalPosition =
    prevProps.position[0] === nextProps.position[0] &&
    prevProps.position[1] === nextProps.position[1] &&
    prevProps.position[2] === nextProps.position[2];

  return equalPosition && prevProps.texture === nextProps.texture;
}

export default memo(Torus, equalProps);

export const FETCH_MEETING_DATA_START = 'FETCH_MEETING_DATA_START';
export const FETCH_MEETING_DATA_SUCCESS = 'FETCH_MEETING_DATA_SUCCESS';
export const FETCH_MEETING_DATA_FAILURE = 'FETCH_MEETING_DATA_FAILURE';

export const CREATE_MEETING_START = 'CREATE_MEETING_START';
export const CREATE_MEETING_SUCCESS = 'CREATE_MEETING_SUCCESS';
export const CREATE_MEETING_FAILURE = 'CREATE_MEETING_FAILURE';

export const CREATE_NEW_OBJECT_START = 'CREATE_NEW_OBJECT_START';
export const CREATE_NEW_OBJECT_SUCCESS = 'CREATE_NEW_OBJECT_SUCCESS';
export const CREATE_NEW_OBJECT_FAILURE = 'CREATE_NEW_OBJECT_FAILURE';

export const REMOVE_OBJECT_START = 'REMOVE_OBJECT_START';
export const REMOVE_OBJECT_SUCCESS = 'REMOVE_OBJECT_SUCCESS';
export const REMOVE_OBJECT_FAILURE = 'REMOVE_OBJECT_FAILURE';

export const SUBSCRIBE_TO_PARTICIPANTS_START = 'SUBSCRIBE_TO_PARTICIPANTS_START';
export const SUBSCRIBE_TO_PARTICIPANTS_SUCCESS = 'SUBSCRIBE_TO_PARTICIPANTS_SUCCESS';
export const JOIN_TO_PARTICIPANTS_SUCCESS = 'JOIN_TO_PARTICIPANTS_SUCCESS';
export const INITIALIZE_LISTENERS_SUCCESS = 'INITIALIZE_LISTENERS_SUCCESS';

export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const UPDATE_RTC_PREFERENCES = 'UPDATE_RTC_PREFERENCES';

export const SUBSCRIBE_TO_OBJECTS_START = 'SUBSCRIBE_TO_OBJECTS_START';
export const SUBSCRIBE_TO_OBJECTS_SUCCESS = 'SUBSCRIBE_TO_OBJECTS_SUCCESS';
export const ADD_OBJECT = 'ADD_OBJECT';
export const REMOVE_OBJECT = 'REMOVE_OBJECT';
export const UPDATE_OBJECT = 'UPDATE_OBJECT';

export const SUBSCRIBE_TO_MESSAGES_START = 'SUBSCRIBE_TO_MESSAGES_START';
export const SUBSCRIBE_TO_MESSAGES_SUCCESS = 'SUBSCRIBE_TO_MESSAGES_SUCCESS';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SEND_NEW_MESSAGE_START = 'SEND_NEW_MESSAGE_START';
export const SEND_NEW_MESSAGE_SUCCESS = 'SEND_NEW_MESSAGE_SUCCESS';
export const SEND_NEW_MESSAGE_FAILURE = 'SEND_NEW_MESSAGE_FAILURE';

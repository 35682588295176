import { SET_MAIN_STREAM, SET_AUDIO, SET_VIDEO } from './types';

const DEFAULT_STATE = {
  mainStream: null,
  video: false,
  audio: false
};

const rtcReducer = (state = DEFAULT_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_MAIN_STREAM:
      return {
        ...state,
        mainStream: payload.mainStream
      };
    case SET_AUDIO:
      return {
        ...state,
        audio: payload.audio
      };
    case SET_VIDEO:
      return {
        ...state,
        video: payload.video
      };
    default:
      return state;
  }
};

export default rtcReducer;

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';

function Monkey({ modelUrl, ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF(modelUrl);
  return (
    <mesh
      // ref={group}
      position={props.position}
      rotation={props.rotation}
      {...props}
      remove={() => {}}
      dispose={null}
      geometry={nodes.Suzanne.geometry}
      material={nodes.Suzanne.material}
    />
  );
}

export default Monkey;

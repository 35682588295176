import * as THREE from 'three';

const DEFAULT_STATE = {
  scene: {
    ground: {
      position: [0, 0.5, 0],
      mesh: {
        rotation: [-Math.PI / 2, 0, 0]
      },
      plane: {
        componentType: 'planeBufferGeometry',
        attach: 'geometry',
        args: [100, 100]
      },
      meshMaterial: {
        componentType: 'meshStandardMaterial',
        attach: 'material',
        color: 'white'
      }
    },
    objects: {
      triangle1: {
        name: 'triangle',
        color: '#ff2060',
        position: [15, 0, -7],
        scale: 0.009,
        rotation: [0, 0, Math.PI / 3]
      },
      triangle2: {
        name: 'triangle',
        color: 'cyan',
        scale: 0.009,
        position: [5, 0, -2],
        rotation: [0, 0, Math.PI / 3]
      },
      triangle3: {
        name: 'triangle',
        color: 'orange',
        scale: 0.009,
        position: [9, 0, -4],
        rotation: [0, 0, Math.PI / 3]
      },
      triangle4: {
        name: 'triangle',
        color: 'white',
        scale: 0.009,
        position: [10, 2, -10],
        rotation: [0, 0, Math.PI / 3]
      }
    },
    sky: {
      distance: 450000,
      sunPosition: [5, 1, 8],
      inclination: 0,
      azimuth: 0.25,
      mieCoefficient: 0.005,
      mieDirectionalG: 0.8,
      rayleigh: 0.5,
      turbidit: 10
    },
    lights: {
      ambLight: {
        intensity: 0.25,
        color: 'white'
      },
      pointLight: {
        color: 'white',
        intensity: 0.7,
        position: [100, 100, 100]
      },
      spotLight: {
        position: [10, 15, 10],
        angle: 0.4
      }
    },
    physics: {
      gravity: [0, -20, 0]
    },
    hud: {
      position: [0, 0, 20]
    }
  },
  objects: {
    cube: {
      boxBufferGeometry: {
        attach: 'geometry'
      },
      meshStandardMaterial: {
        attach: 'material',
        hoverColor: 'gray',
        opacityGlass: 0.7,
        transparent: false
      },
      colorCube: {
        ifGlass: 'skyblue',
        else: 'white'
      },
      cubePhysics: {
        type: 'Static'
      }
    },

    sphere: {
      sphereBufferGeometry: {
        attach: 'geometry'
      },
      material: {
        transparent: false,
        opacity: 0.0, // 0-1
        depthTest: true,
        depthWrite: true,
        alphaTest: 0, // 0-1
        visible: true,
        side: THREE.FrontSide // THREE.FrontSide ,THREE.BackSide ,THREE.DoubleSide
      },
      meshStandardMaterial: {
        attach: 'material',
        hoverColor: 'gray',
        color: 'pink',
        emissive: '#000000',
        roughness: 0, // 0-1
        metalness: 0, // 0-1
        wireframe: false
      },

      spherePhysics: {
        type: 'Static'
      }
    },

    torus: {
      torusGeometry: {
        attach: 'geometry'
      },
      meshStandardMaterial: {
        attach: 'material',
        hoverColor: 'gray'
      },
      torusPhysics: {
        type: 'Static'
      }
    },
    cylinder: {
      cylinderBufferGeometry: {
        attach: 'geometry'
      },
      meshStandardMaterial: {
        attach: 'material',
        hoverColor: 'gray'
      },
      cylinderPhysics: {
        type: 'Static'
      }
    },
    plane: {
      planeGeometry: {
        attach: 'geometry'
      },
      meshStandardMaterial: {
        attach: 'material',
        hoverColor: 'gray'
      },
      planePhysics: {
        type: 'Static'
      }
    },

    submarine: {
      modelUrl:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/models%2Fsubmarine.gltf?alt=media&token=34374c9e-bf00-49fb-87b0-afcd0f41ed53',
      scale: [0.2, 0.2, 0.2]
    },
    frame: {
      url: 'https://picsum.photos/200/300'
    },

    monkey: {
      modelUrl:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/models%2Fmonkey.gltf?alt=media&token=59c37061-1cf3-4608-a4a7-007e9f85fce6',
      scale: [0.4, 0.4, 0.4]
    },
    triangle: {
      color: 'pink',
      triangleURL:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/models%2Ftriangle.svg?alt=media&token=7a5a0a79-f019-4573-b6ec-9caf621c3019',
      scale: [0.01, 0.01, 0.01]
    }
  }
};

const meetingReducer = (state = DEFAULT_STATE, action) => {
  const { type } = action;
  // console.log({ ...action, state });
  switch (type) {
    default:
      return state;
  }
};

export default meetingReducer;

import { useLoader } from '@react-three/fiber';
import React, { useMemo, useRef } from 'react';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

function Triangle({ color, triangleURL, ...props }) {
  const ref = useRef();
  const {
    paths: [path]
  } = useLoader(SVGLoader, triangleURL);

  const geom = useMemo(
    () => SVGLoader.pointsToStroke(path.subPaths[0].getPoints(), path.userData.style),
    []
  );
  return (
    <group ref={ref}>
      <mesh geometry={geom} {...props}>
        <meshBasicMaterial color={color} toneMapped={false} />
      </mesh>
    </group>
  );
}

export default Triangle;

import * as THREE from 'three';

export const types = {
  position: 'Input3',
  rotation: 'Input3',
  scale: 'Input3',
  material: {
    transparent: 'Bool',
    opacity: 'Float-Range-0-1', // 0-1
    depthTest: 'Bool',
    depthWrite: 'Bool',
    alphaTest: 'Float-Range-0-1', // 0-1
    visible: 'Bool',
    side: 'Select-MaterialSide' // THREE.FrontSide ,THREE.BackSide ,THREE.DoubleSide
  }
};

export const REFERENCE = {
  // MATERIALS

  material: {
    attach: 'material',
    transparent: false,
    opacity: 0.0, // 0-1
    depthTest: true,
    depthWrite: true,
    alphaTest: 0, // 0-1
    visible: true,
    side: THREE.FrontSide // THREE.FrontSide ,THREE.BackSide ,THREE.DoubleSide
  },

  // this gives a more accurate and realistic looking result than the MeshLambertMaterial or MeshPhongMaterial,
  // at the cost of being somewhat more computationally expensive.
  // light affects

  // LineBasicMaterial
  // LineDashedMaterial

  meshStandardMaterial: {
    color: '0xffffff',
    emissive: '#000000',
    roughness: 0, // 0-1
    metalness: 0, // 0-1
    wireframe: false,
    vertexColors: false,
    fog: true,
    envMaps: null, // 'envMapTexture'
    map: null, // 'mapTexture',
    alphaMap: null, // 'alphaMapTexture',
    roughnessMap: null, // 'roughnessMapTexture',
    displacementMap: null, // 'displacementMapTexture'
    normalMap: null, // 'normalMapTexture'
    displacementScale: 1.0
  },

  // very performant but lousy quality
  // This material is not affected by lights.

  meshBasicMaterial: {
    color: '0xffffff',
    wireframe: false,
    vertexColors: false,
    envMaps: null, // 'envMapTexture'
    map: null, // 'mapTexture',
    alphaMap: null, // 'alphaMapTexture',
    combine: THREE.MultiplyOperation, // THREE.MixOperation, THREE.AddOperation
    reflectivity: 1, // 0-1, for envMaps:reflection
    refractionRatio: 0.98, // 0-1, envMaps; refraction
    fog: true
  },

  // A material for drawing geometry by depth.
  // Depth is based off of the camera near and far plane. White is nearest, black is farthest.
  meshDepthMaterial: {
    alphaMap: null, // 'alphaMapTexture',
    displacementMap: null, // 'displacementMapTexture'
    displacementScale: 1.0,
    fog: true,
    map: null, // 'mapTexture'
    wireframe: false
  },

  // meshDistanceMaterial

  // A material for non-shiny surfaces, without specular highlights.
  // performance will be greater when using this material over the MeshPhongMaterial, MeshStandardMaterial or MeshPhysicalMaterial
  meshLambertMaterial: {
    color: '0xffffff',
    emissive: '#000000',
    wireframe: false,
    vertexColors: false,
    fog: true,
    envMaps: null, // 'envMapTexture'
    map: null, // 'mapTexture',
    alphaMap: null, // 'alphaMapTexture',
    combine: THREE.MultiplyOperation, // THREE.MixOperation, THREE.AddOperation
    reflectivity: 1, // 0-1, for envMaps:reflection
    refractionRatio: 0.98 // 0-1, envMaps; refraction
  },
  //  MatCap (or Lit Sphere) texture, which encodes the material color and shading.
  // does not respond to lights since

  meshMatcapMaterial: {
    color: '0xffffff',
    flatShading: false,
    matcap: null, // matcapTexture
    alphaMap: null, // 'alphaMapTexture'
    map: null, // 'mapTexture'
    normalMap: null, // 'normalMapTexture',
    displacementMap: null, // 'displacementMapTexture'
    displacementScale: 1.0,
    fog: true
  },

  // maps the normal vectors to RGB colors.
  meshNormalMaterial: {
    flatShading: false,
    wireframe: false,
    normalMap: null, // 'normalMapTexture',
    displacementMap: null, // 'displacementMapTexture'
    displacementScale: 1.0,
    fog: true
  },
  // Unlike the Lambertian model used in the MeshLambertMaterial this can simulate shiny surfaces (specular lights)
  // cost of some graphical accuracy.
  meshPhongMaterial: {
    color: '0xffffff',
    emissive: '#000000',
    specular: '0x111111',
    shininess: 30,
    flatShading: false,
    wireframe: false,
    vertexColors: false,
    fog: true,
    envMaps: null, // 'envMapTexture'
    map: null, // 'mapTexture',
    alphaMap: null, // 'alphaMapTexture',
    combine: THREE.MultiplyOperation, // THREE.MixOperation, THREE.AddOperation
    reflectivity: 1, // 0-1, for envMaps:reflection
    refractionRatio: 0.98, // 0-1, envMaps; refraction
    displacementMap: null, // 'displacementMapTexture'
    displacementScale: 1.0,
    normalMap: null // 'normalMapTexture'
  }

  // MeshPhysicalMaterial
  // MeshToonMaterial
  // PointsMaterial
  // RawShaderMaterial
  // ShadowMaterial
  // SpriteMaterial
};

import { Form, Formik } from 'formik';

export default function FormikContainer({
  children,
  initialValues,
  enableReinitialize,
  onSubmit,
  // validationSchema,
  className
}) {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={onSubmit}>
      <Form className={className}>{children}</Form>
    </Formik>
  );
}

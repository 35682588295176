import React, { useRef, useEffect } from 'react';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { useThree, extend, useFrame } from '@react-three/fiber';

extend({ OrbitControls });

export default function FPVOrbit({
  playerPosition,
  setPlayerPosition,
  velocity,
  direction,
  delta,
  ...props
}) {
  const { camera, gl } = useThree();
  const controls = useRef();

  useEffect(() => {
    document.querySelector('canvas').addEventListener('click', () => {
      controls.current.lock();
      controls.update();
    });
  }, []);

  useFrame(() => {
    // controls.current.target.set(playerPosition.x, playerPosition.y, playerPosition.z);
    // controls.current.target.set(...playerPosition);
    // camera.current.target.set(...playerPosition);
    // camera.lookAt(...playerPosition);

    // move model & camera
    const moveX = direction.x * 2 * delta * 0.5;
    const moveZ = direction.z * 2 * delta * 0.5;
    camera.position.set(camera.position.x + moveX, camera.position.y, camera.position.z + moveZ);
    controls.current.target.set(playerPosition.x, playerPosition.y + 1, playerPosition.z);
    setPlayerPosition((playerPosition.x += moveX), playerPosition.y, (playerPosition.z += moveZ));

    // controls.update();
  });

  return (
    <orbitControls
      ref={controls}
      // target={(playerPosition.x, playerPosition.y, playerPosition.z)}
      args={[camera, gl.domElement]}
      enableDamping
      dampingFactor={0.05}
      enableZoom
      minDistance={3}
      maxDistance={7}
      enablePan={false}
      // maxAzimuthAngle={Math.PI / 2 - 0.05}
      maxPolarAngle={Math.PI / 2 - 0.05}
      {...props}
    />
  );
}

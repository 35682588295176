import { nanoid } from 'nanoid';
import create from 'zustand';

const getLocalStorage = (key) => JSON.parse(window.localStorage.getItem(key));
const setLocalStorage = (key, value) => window.localStorage.setItem(key, JSON.stringify(value));

export default function useStore() {
  return create((set) => ({
    texture: 'dirt',
    cubes: getLocalStorage('world') || [],
    addCube: (x, y, z) =>
      set((state) => ({
        cubes: [
          ...state.cubes,
          { name: 'cube', key: nanoid(), position: [x, y, z], texture: state.texture }
        ]
      })),
    removeCube: (x, y, z) => {
      set((state) => ({
        cubes: state.cubes.filter((cube) => {
          const [_x, _y, _z] = cube.pos;
          return _x !== x || _y !== y || _z !== z;
        })
      }));
    },
    setTexture: (texture) => {
      set(() => ({
        texture
      }));
    },
    saveWorld: () =>
      set((state) => {
        setLocalStorage('world', state.cubes);
      })
  }));
}

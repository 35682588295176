import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/home/Hero';

function Home() {
  return (
    <Layout>
      <Hero />
    </Layout>
  );
}

export default Home;

import { ExclamationCircleIcon } from '@heroicons/react/solid';

import { classNames } from '../../../utils';

export default function Input({ label, type = 'text', size = '20', name, errorMessage, ...props }) {
  return (
    <div>
      {label && (
        <label htmlFor={name} className='block text-sm font-medium text-gray-700'>
          {label}
        </label>
      )}
      <div className='mt-1 relative rounded-md shadow-sm'>
        <input
          type={type}
          name={name}
          id={name}
          size={size}
          className={classNames(
            'block pr-10',
            errorMessage ? 'border-red-300 text-red-900 placeholder-red-300' : '',
            'focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
          )}
          aria-invalid='true'
          aria-describedby='email-error'
          {...props}
        />
        {errorMessage && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
          </div>
        )}
      </div>
      {/* {errorMessage && (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {errorMessage}
        </p>
      )} */}
    </div>
  );
}

import {
  PencilAltIcon,
  PhotographIcon,
  PlayIcon,
  PuzzleIcon,
  SearchIcon
} from '@heroicons/react/solid';
import { useEffect, useState } from 'react';

import { getDocsByCollectionPath } from '../../../firebase/firestore';

const tabs = [
  { name: 'Scenes', key: 'canvas', icon: PhotographIcon, current: true },
  { name: '360° Video', key: 'video360', icon: PlayIcon },
  { name: 'Games', key: 'game', icon: PuzzleIcon },
  { name: 'Collaboration', key: 'collaboration', icon: PencilAltIcon }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Tabs({ setShowSearchBar, setSceneId, setActivityType }) {
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);
  const [scenes, setScenes] = useState([]);
  const [videos, setVideos] = useState([]);
  const [games, setGames] = useState([]);

  useEffect(() => {
    const getScenes = async () => {
      const res = await getDocsByCollectionPath('scenes');
      setScenes(res);
    };
    const getVideos = async () => {
      const res = await getDocsByCollectionPath('videos');
      setVideos(res);
    };
    const getGames = async () => {
      const res = await getDocsByCollectionPath('games');
      setGames(res);
    };

    switch (selectedTab) {
      case 'canvas':
        getScenes();
        break;
      case 'video360':
        getVideos();
        break;
      case 'game':
        getGames();
        break;
      default:
    }
  }, [selectedTab]);

  const renderContent = () => {
    switch (selectedTab) {
      case 'canvas':
        return scenes.map((i) => (
          <li
            key={i.key}
            onClick={() => {
              setSceneId(i.key);
              setActivityType('canvas');
              setShowSearchBar(false);
            }}
            className='col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200'>
            <div className='h-full max-w-sm rounded overflow-hidden shadow-lg'>
              <img className='w-full' src={i.previewURL} alt='Sunset in the mountains' />
              <div className='px-6 py-4'>
                <div className='font-bold text-xl mb-2'>{i.name}</div>
                {/* <p className='text-gray-700 text-base'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia,
                  nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                </p> */}
              </div>
            </div>
          </li>
        ));
      case 'game':
        return games.map((i) => (
          <li
            key={i.key}
            onClick={() => {
              setSceneId(i.key);
              setActivityType('game');
              setShowSearchBar(false);
            }}
            className='col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200'>
            <div className='h-full max-w-sm rounded overflow-hidden shadow-lg'>
              <img className='w-full' src={i.previewURL} alt='Sunset in the mountains' />
              <div className='px-6 py-4'>
                <div className='font-bold text-xl mb-2'>{i.name}</div>
                {/* <p className='text-gray-700 text-base'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia,
                  nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                </p> */}
              </div>
            </div>
          </li>
        ));
      case 'video360':
        return videos.map(
          ({
            etag,
            id: { videoId },
            snippet: {
              title,
              thumbnails: {
                medium: { url }
              }
            }
          }) => (
            <li
              key={etag}
              onClick={() => {
                setActivityType('video360');
                setSceneId(videoId);
                setShowSearchBar(false);
              }}
              className='col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200'>
              <div className='h-full max-w-sm rounded overflow-hidden shadow-lg'>
                <img className='w-full' src={url} alt='Sunset in the mountains' />
                <div className='px-6 py-4'>
                  <div className='font-bold text-xl mb-2'>{title}</div>
                  {/* <p className='text-gray-700 text-base'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia,
                  nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                </p> */}
                </div>
              </div>
            </li>
          )
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className='sm:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id='tabs'
          name='tabs'
          className='block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
          defaultValue={tabs.find((tab) => selectedTab === tab.key).name}>
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {tabs.map((tab) => (
              <button
                onClick={() => setSelectedTab(tab.key)}
                type='button'
                key={tab.name}
                className={classNames(
                  tab.key === selectedTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.key === selectedTab ? 'page' : undefined}>
                <tab.icon
                  className={classNames(
                    tab.key === selectedTab
                      ? 'text-indigo-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden='true'
                />
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className='pt-4'>
        <ul className='grid grid-cols-3 gap-6'>{renderContent()}</ul>
      </div>
    </div>
  );
}

import { useFormikContext } from 'formik';
import { useState } from 'react';

import Notification from '../../components/design-system/Notification';
import { addDocByCollectionPath, setDocByPathAndId } from '../../firebase/firestore';

export default function ObjectTree({ setSelectedItem, setIsTemplateSelectorOpen }) {
  const { values } = useFormikContext();
  const [showNotification, setShowNotification] = useState(false);

  const handleSaveTemplate = async () => {
    const templateMetadata = {
      name: 'New Template',
      imageUrl: ''
    };
    const newDocId = await addDocByCollectionPath('templateMetadata', templateMetadata);
    await setDocByPathAndId('templateData', newDocId, { data: values });
    setShowNotification(true);
  };

  const handleOpenTemplateSelector = () => {
    setIsTemplateSelectorOpen(true);
  };

  const renderTree = (key = 'main', level = 1) => {
    const { title, children = [] } = values[key];
    return (
      <div>
        <div
          onClick={() => setSelectedItem(key)}
          className='hover:cursor-pointer'
          role='treeitem'
          tabIndex={0}
          onKeyDown={() => {}}>
          <span>
            {'-'.repeat(level)} {title}
          </span>
        </div>
        {children.map((i) => renderTree(i, level + 1))}
      </div>
    );
  };

  return (
    <div className='h-full border-2 p-2 border-indigo-400 col-span-2'>
      <nav aria-label='Sidebar' className='sticky top-6 divide-y divide-gray-300'>
        {renderTree()}
      </nav>
      <div style={{ marginTop: '3rem' }}>
        <button
          onClick={handleOpenTemplateSelector}
          type='button'
          className='py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'>
          Select a template
        </button>
        <button
          onClick={handleSaveTemplate}
          type='button'
          className='focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-100 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-500 dark:focus:ring-green-600'>
          Save this template
        </button>
      </div>
      <Notification show={showNotification} setShow={setShowNotification} />
    </div>
  );
}

import React from 'react';
import { useField } from 'formik';
// import TextError from './TextError';

export default function RadioButton({ label, name, options }) {
  const [field, , helper] = useField(name);
  const { value } = field;

  return (
    <>
      <fieldset onChange={(e) => helper.setValue(e.target.value)}>
        <label htmlFor={name} className='text-base font-medium text-gray-900'>
          {label}
        </label>

        <legend className='sr-only'>{label}</legend>
        <div id={name} className='space-y-5'>
          {options.map((option) => (
            <div key={option.id} className='relative flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  id={option.id}
                  aria-describedby={option.description ? `${option.id}-description` : null}
                  name={option.id}
                  type='radio'
                  value={option.id}
                  checked={value === option.id}
                  // defaultChecked={option.id === "small"}
                  className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300'
                  {...field}
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor={option.id} className='font-medium text-gray-700'>
                  {option.name}
                </label>
                {option.description ? (
                  <p id={`${option.id}-description`} className='text-gray-500'>
                    {option.description}
                  </p>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </fieldset>
      {/* {meta.touched && meta.error ? <TextError errorMessage={meta.error} /> : null} */}
    </>
  );
}

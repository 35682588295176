/* eslint-disable no-use-before-define */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useMemo, useState } from 'react';
import { Vector3, MathUtils } from 'three';
import { useThree, useFrame } from '@react-three/fiber';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useConvexPolyhedron } from '@react-three/cannon';
import * as THREE from 'three'
import { Geometry } from 'three-stdlib';
import { setByPath } from '../../../firebase/database';
import FPVOrbit from './FPVOrbit';
import useKeyboardControls from '../../../hooks/useKeyboardControls';

// const { lerp } = MathUtils
/**
 * Returns legacy geometry vertices, faces for ConvP
 * @param {THREE.BufferGeometry} bufferGeometry
 */

// const SPEED = 6;

function toConvexProps(bufferGeometry) {
  const geo = new Geometry().fromBufferGeometry(bufferGeometry);
  // Merge duplicate vertices resulting from glTF export.
  // Cannon assumes contiguous, closed meshes to work
  geo.mergeVertices();
  return [geo.vertices.map((v) => [v.x, v.y, v.z]), geo.faces.map((f) => [f.a, f.b, f.c]), []]; // prettier-ignore
}

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

function isEqual(vectorA, vectorB) {
  return vectorA.x === vectorB.x && vectorA.y === vectorB.y && vectorA.z === vectorB.z;
}

function Avatar({ avatarUrl, setPlayerPosition, playerPosition, setVelocity, setDelta, setDirection, ...props }) {
  const { playerPhysics, position, speed, meetingId, uid, cameraPosition } = props;
  // const { camera } = useThree();
  const { moveForward, moveBackward, moveLeft, moveRight, jump } = useKeyboardControls();

  const { nodes, materials, geometry, animations } = useGLTF('https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/avatars%2FSoldier.glb?alt=media&token=22cedb73-c3c9-496b-982d-f75b8a24ab8f');
  const geo = useMemo(() => toConvexProps(nodes.vanguard_visor.geometry), [nodes]);
  const [ref, api] = useConvexPolyhedron(() => ({
    type: 'Dynamic',
    mass: 0,
    args: geo,
    position: [0, 1, 0],
    speed
  }));

  const modelRef = useRef(null)
  const velocity = useRef([0, 0, 0]);
  // const direction = new Vector3();

  useEffect(() => {
    api.velocity.subscribe((v) => {
      velocity.current = v;
    });
    setByPath(`meetings/${meetingId}/participants/${uid}/three/position`, [
      ref.current.position.x,
      ref.current.position.y,
      ref.current.position.z
    ]);
  }, [meetingId, ref, uid, api.velocity, moveForward, moveBackward, moveLeft, moveRight, jump]);

  const group = useRef();
  // const { actions } = useAnimations(animations, group);
  // const [action, setAction] = useState(ANIMATIONS.Idle);
  // const previousAction = usePrevious(action);

  // useEffect(() => {
  //   if (previousAction) {
  //     actions[previousAction].fadeOut(0.2);
  //     actions[action].stop();
  //   }
  //   actions[action].play();
  //   actions[action].fadeIn(0.2);
  // }, [actions, action, previousAction]);

  // useFrame((_, delta) => {
  //   const rotateAngle = new THREE.Vector3(0, 1, 0)
  //   const cameraTarget = new THREE.Vector3()
  //   const rotateQuaternion = new THREE.Quaternion()
  //   const frontVector = new Vector3(0, 0, (moveBackward ? 1 : 0) - (moveForward ? 1 : 0));
  //   const sideVector = new Vector3((moveLeft ? 1 : 0) - (moveRight ? 1 : 0), 0, 0);

    // direction
    //   .subVectors(frontVector, sideVector)
    //   .normalize()
    //   .multiplyScalar(SPEED)
    //   .applyEuler(camera.rotation);

    // const angleYCameraDirection = Math.atan2(
    //   (camera.position.x - ref.current.position.x),
    //   (camera.position.z - ref.current.position.z))

    // if (isEqual(direction, new Vector3(0, 0, 0))) {
    //   setAction(ANIMATIONS.Idle);
    // } else {
    //   setAction(ANIMATIONS.Walk);
    //   let directionOffset = 0;
    //   if (moveForward) {
    //     if (moveLeft) {
    //       directionOffset = Math.PI / 4; // w+a
    //     } else if (moveRight) {
    //       directionOffset = - Math.PI / 4; // w+d
    //     }
    //   } else if (moveBackward) {
    //     if (moveLeft) {
    //       directionOffset = Math.PI / 4 + Math.PI / 2; // s+a
    //     } else if (moveRight) {
    //       directionOffset = -Math.PI / 4 - Math.PI / 2; // s+d
    //     } else {
    //       directionOffset = Math.PI; // s
    //     }
    //   } else if (moveLeft) {
    //     directionOffset = Math.PI / 2; // a
    //   } else if (moveRight) {
    //     directionOffset = - Math.PI / 2; // d
    //   }

    //   if (modelRef.current) {
    //     modelRef.current.rotation.z = lerp(modelRef.current.rotation.z, directionOffset + angleYCameraDirection, delta * 2)
    //   }

    // }


    // api.velocity.set(direction.x, velocity.current[1], direction.z);

    // if (jump && Math.abs(velocity.current[1].toFixed(2)) < 0.05) {
    //   api.velocity.set(velocity.current[0], 8, velocity.current[2]);
    // }
    // ref.current.getWorldPosition(ref.current.position);

    // // rotateQuaternion.setFromAxisAngle(rotateAngle, angleYCameraDirection + directionOffset);

    // ref.current.getWorldQuaternion(rotateQuaternion, 0.2);

    // setPlayerPosition(ref.current.position)
    // setVelocity(new Vector3(velocity.current[0], 8, velocity.current[2]))
    // setDirection(direction)
    // setDelta(delta)

  // });

  return (
    <group ref={group}>
      <group ref={ref} {...props} dispose={null}>
        <group ref={modelRef} rotation={[-Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh name="vanguard_Mesh" geometry={nodes.vanguard_Mesh.geometry} material={materials.VanguardBodyMat} skeleton={nodes.vanguard_Mesh.skeleton} />
          <skinnedMesh name="vanguard_visor" geometry={nodes.vanguard_visor.geometry} material={materials.Vanguard_VisorMat} skeleton={nodes.vanguard_visor.skeleton} />
        </group>
      </group>
    </group>
  );

}

export default function Player(props) {
  // const [playerPosition, setPlayerPosition] = useState(new Vector3())
  // const [velocity, setVelocity] = useState(new Vector3())
  // const [direction, setDirection] = useState(new Vector3())
  // const [delta, setDelta] = useState(0)

  useFrame(() => {
  })

  // return (
  //   <>
  //     <FPVOrbit playerPosition={playerPosition} setPlayerPosition={setPlayerPosition} velocity={velocity} direction={direction} delta={delta} />
  //     <Avatar setPlayerPosition={setPlayerPosition} setVelocity={setVelocity} setDirection={setDirection} setDelta={setDelta} {...props} />
  //   </>
  // );
  return (
    <Avatar {...props} />
  );
}

// const ANIMATIONS = {
//   'Idle': 'Idle',
//   'Walk': 'Walk',
//   'RunForward': 'RunForward',
//   'Jump': 'Jump',
//   'Leftturn': 'Leftturn',
//   'Rightturn': 'Rightturn',
// }
import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setMainStream } from '../../redux/rtc/actions';
import { updateRTCPreferencesAsync } from '../../redux/meeting/thunks';

import MeetingFooter from './MeetingFooter';
import Participants from './Participants';
import './MainRTC.css';

function MainRTC() {
  const dispatch = useDispatch();
  const participants = useSelector((st) => st.meeting.participants.byId);
  const currentUser = useSelector((st) => st.auth.user.data);
  const stream = useSelector((st) => st.rtc.mainStream);

  const { meetingId } = useParams();
  const participantRef = useRef(participants);

  const onMicClick = (audioEnabled) => {
    if (stream) {
      stream.getAudioTracks()[0].enabled = audioEnabled;
    }
    dispatch(updateRTCPreferencesAsync(meetingId, currentUser.uid, { audio: audioEnabled }));
  };
  const onVideoClick = (videoEnabled) => {
    if (stream) {
      stream.getVideoTracks()[0].enabled = videoEnabled;
    }
    dispatch(updateRTCPreferencesAsync(meetingId, currentUser.uid, { video: videoEnabled }));
  };

  useEffect(() => {
    participantRef.current = participants;
  }, [participants]);

  const updateStream = (st) => {
    for (const sender of Object.values(participantRef.current)) {
      if (!sender.currentUser) {
        const peerConnection = sender.peerConnection
          .getSenders()
          .find((s) => (s.track ? s.track.kind === 'video' : false));
        peerConnection.replaceTrack(st.getVideoTracks()[0]);
      }
    }
    dispatch(setMainStream(stream));
  };

  const onScreenShareEnd = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true
    });

    localStream.getVideoTracks()[0].enabled = Object.values(currentUser)[0].video;

    updateStream(localStream);

    // dispatch(updateUser({ screen: false }));
  };

  const onScreenClick = async () => {
    let mediaStream;
    if (navigator.getDisplayMedia) {
      mediaStream = await navigator.getDisplayMedia({ video: true });
    } else if (navigator.mediaDevices.getDisplayMedia) {
      mediaStream = await navigator.mediaDevices.getDisplayMedia({
        video: true
      });
    } else {
      mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { mediaSource: 'screen' }
      });
    }

    mediaStream.getVideoTracks()[0].onended = onScreenShareEnd;

    updateStream(mediaStream);

    // dispatch(updateUser({ screen: true }));
  };

  if (!participants) {
    return null;
  }
  const currentParticipant =
    participants[Object.keys(participants).find((k) => k === currentUser.uid)];

  if (!currentParticipant) {
    return null;
  }
  return (
    <>
      <Participants participants={participants} currentUser={currentUser} stream={stream} />
      <MeetingFooter
        onScreenClick={onScreenClick}
        onMicClick={onMicClick}
        onVideoClick={onVideoClick}
        currentParticipantPreferences={currentParticipant.rtc}
      />
    </>
  );
}

export default MainRTC;

import { Physics } from '@react-three/cannon';
import { Sky, Stars } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TextureLoader } from 'three';

import {
  createNewObjectAsync,
  removeObjectAsync,
  subscribeToObjectsAsync
} from '../../../redux/meeting/thunks';
import { mergeDeep } from '../../../utils';
import { Effect, Ground, Objects, OtherPlayer, Player } from '../_common';

export default function CanvasWrapper({ user, sceneData = {} }) {
  const { meetingId } = useParams();
  const dispatch = useDispatch();
  const objects = useSelector((st) => st.meeting.objects);
  const participants = useSelector((st) => st.meeting.participants);
  const baseSceneData = useSelector((st) => st.base.scene);
  const baseObjectData = useSelector((st) => st.base.objects);

  const { ground, sky, lights, physics } = mergeDeep(structuredClone(baseSceneData), sceneData);

  useEffect(() => {
    // Subscribe to realtime objects
    if (sceneData?.realtimeObjects && !objects.hasSubscribed && !objects.isSubscribing) {
      dispatch(subscribeToObjectsAsync(meetingId));
    }
  }, [dispatch, meetingId, objects.hasSubscribed, objects.isSubscribing]);

  const addObject = (x, y, z, activeTexture = 'dirt') => {
    const newObject = {
      name: 'cube',
      position: [x, y, z],
      texture: activeTexture
    };
    dispatch(createNewObjectAsync(meetingId, newObject));
  };

  const removeObject = (objectId) => {
    dispatch(removeObjectAsync(meetingId, objectId));
  };

  const styleCanvas = { backgroundColor: sceneData?.sky?.hasStars ? 'black' : '' };

  return (
    <>
      <div className='dot' />
      <Canvas dpr={[1, 2]} style={styleCanvas}>
        {sceneData?.sky?.hasStars ? (
          <Stars />
        ) : (
          <Sky
            distance={sky.distance}
            sunPosition={sky.sunPosition}
            inclination={sky.inclination}
            azimuth={sky.azimuth}
          />
        )}
        {sceneData?.hasEffect && <Effect />}

        <ambientLight intensity={lights.ambLight.intensity} color={lights.ambLight.color} />
        <pointLight
          castShadow
          intensity={lights.pointLight.intensity}
          position={lights.pointLight.position}
        />

        <Physics gravity={physics.gravity}>
          <Suspense fallback={null}>
            <Ground {...ground} onClick={addObject} />
            {Object.entries(participants.byId).map(([key, participant]) =>
              key === user.uid ? (
                <Player
                  avatarConfig={user.avatarConfig}
                  key={key}
                  meetingId={meetingId}
                  {...participant.three}
                  uid={key}
                />
              ) : (
                <OtherPlayer key={key} meetingId={meetingId} {...participant.three} uid={key} />
              )
            )}
            <Objects
              baseObjectData={baseObjectData}
              sceneObjects={objects.byId}
              addObject={addObject}
              removeObject={removeObject}
            />
          </Suspense>
        </Physics>
      </Canvas>
    </>
  );
}

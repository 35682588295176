import React, { useState } from 'react';
import * as THREE from 'three';

function Wall({ ...props }) {
  return (
    <mesh position={props.position} rotation={props.rotation}>
      <boxGeometry attach='geometry' args={[6, 3, 0.5]} />
      <meshStandardMaterial attach='material' color={props.color} side={THREE.DoubleSide} />
    </mesh>
  );
}

export default Wall;

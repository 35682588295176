import { useField } from 'formik';

export default function Checkbox({ label, name, description, ...props }) {
  const [field, meta] = useField(name);

  return (
    <div className='relative flex items-start mt-2 mb-2'>
      <div className='flex items-center h-5'>
        <input
          id={name}
          aria-describedby={description || ''}
          name={name}
          type='checkbox'
          className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
          errorMessage={meta.touched && meta.error ? meta.error : ''}
          {...field}
          {...props}
        />
      </div>
      <div className='ml-3 text-sm'>
        <label htmlFor={name} className='font-medium text-gray-700'>
          {label}
        </label>
        {description ? (
          <p id={`${name}-description`} className='text-gray-500'>
            {description}
          </p>
        ) : null}
      </div>
    </div>
  );
}

import React from 'react';
import { useField } from 'formik';
import Select from '../design-system/form/Select';

export default function SelectFormik({ label, name, options }) {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  return (
    <Select
      id={name}
      label={label}
      value={value}
      onChange={setValue}
      options={options}
      errorMessage={meta.touched && meta.error ? meta.error : ''}
      {...field}
    />
  );
}

// /* This example requires Tailwind CSS v2.0+ */
// import { Fragment, useState } from 'react'
// import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

// const people = [
//   {
//     id: 1,
//     name: 'Wade Cooper',
//     avatar:
//       'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 2,
//     name: 'Arlene Mccoy',
//     avatar:
//       'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 3,
//     name: 'Devon Webb',
//     avatar:
//       'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80',
//   },
//   {
//     id: 4,
//     name: 'Tom Cook',
//     avatar:
//       'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 5,
//     name: 'Tanya Fox',
//     avatar:
//       'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 6,
//     name: 'Hellen Schmidt',
//     avatar:
//       'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 7,
//     name: 'Caroline Schultz',
//     avatar:
//       'https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 8,
//     name: 'Mason Heaney',
//     avatar:
//       'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 9,
//     name: 'Claudie Smitham',
//     avatar:
//       'https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 10,
//     name: 'Emil Schaefer',
//     avatar:
//       'https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
// ]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

// export default function Example() {
//   const [selected, setSelected] = useState(people[3])

//   return (
//     <Listbox value={selected} onChange={setSelected}>
//       {({ open }) => (
//         <>
//           <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>
//           <div className="mt-1 relative">
//             <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
//               <span className="flex items-center">
//                 <img src={selected.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
//                 <span className="ml-3 block truncate">{selected.name}</span>
//               </span>
//               <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
//                 <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
//               </span>
//             </Listbox.Button>

//             <Transition
//               show={open}
//               as={Fragment}
//               leave="transition ease-in duration-100"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
//                 {people.map((person) => (
//                   <Listbox.Option
//                     key={person.id}
//                     className={({ active }) =>
//                       classNames(
//                         active ? 'text-white bg-indigo-600' : 'text-gray-900',
//                         'cursor-default select-none relative py-2 pl-3 pr-9'
//                       )
//                     }
//                     value={person}
//                   >
//                     {({ selected, active }) => (
//                       <>
//                         <div className="flex items-center">
//                           <img src={person.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
//                           <span
//                             className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
//                           >
//                             {person.name}
//                           </span>
//                         </div>

//                         {selected ? (
//                           <span
//                             className={classNames(
//                               active ? 'text-white' : 'text-indigo-600',
//                               'absolute inset-y-0 right-0 flex items-center pr-4'
//                             )}
//                           >
//                             <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                           </span>
//                         ) : null}
//                       </>
//                     )}
//                   </Listbox.Option>
//                 ))}
//               </Listbox.Options>
//             </Transition>
//           </div>
//         </>
//       )}
//     </Listbox>
//   )
// }

// TOGGLES
//   |
//   V

//   /* This example requires Tailwind CSS v2.0+ */
// import { useState } from 'react'
// import { Switch } from '@headlessui/react'

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

// export default function Example() {
//   const [enabled, setEnabled] = useState(false)

//   return (
//     <Switch.Group as="div" className="flex items-center justify-between">
//       <span className="flex-grow flex flex-col">
//         <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
//           Available to hire
//         </Switch.Label>
//         <Switch.Description as="span" className="text-sm text-gray-500">
//           Nulla amet tempus sit accumsan. Aliquet turpis sed sit lacinia.
//         </Switch.Description>
//       </span>
//       <Switch
//         checked={enabled}
//         onChange={setEnabled}
//         className={classNames(
//           enabled ? 'bg-indigo-600' : 'bg-gray-200',
//           'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
//         )}
//       >
//         <span
//           aria-hidden="true"
//           className={classNames(
//             enabled ? 'translate-x-5' : 'translate-x-0',
//             'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
//           )}
//         />
//       </Switch>
//     </Switch.Group>
//   )
// }

import { Accordion } from 'flowbite-react';
import { useFormikContext } from 'formik';
import { nanoid } from 'nanoid';
import { useState } from 'react';

import Cards from '../../components/design-system/form/Cards';
import {
  Checkbox,
  Input,
  Input3 // Checkbox,
  // ColorPicker,
  // RadioButton,
  // Select,
  // SubmitButtons
} from '../../components/form';
import { types } from './reference';

const objectOptions = {
  Primitives: [
    {
      id: 1,
      title: 'Cube',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Fcube.png?alt=media&token=d9b211e8-3076-4ae7-b89d-113df76c1e05'
    },
    {
      id: 2,
      title: 'Sphere',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Fsphere.png?alt=media&token=f5815a80-c251-4455-b40e-d542f8c932d9'
    },
    {
      id: 3,
      title: 'Torus',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Ftorus.png?alt=media&token=b3948f6c-7755-4ad7-95db-ff832f2f7c26'
    },
    {
      id: 4,
      title: 'Plane',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Fplane.png?alt=media&token=d8b1c6b7-1273-4a3a-95f8-ccd1afb0a0e0'
    },
    {
      id: 5,
      title: 'Cylinder',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Fcylinder.png?alt=media&token=3aec385d-2eae-4758-b6e8-4f622b864ad2'
    },
    {
      id: 7,
      title: 'Triangle',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Ftriangle.png?alt=media&token=996b6be8-a6ca-4d3c-b695-3c02861b04d8'
    }
  ],
  Assets: [
    {
      id: 6,
      title: 'Submarine',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Fsubmarine.png?alt=media&token=54e8bab8-0900-463d-8400-d0ebe7e37add'
    },
    {
      id: 8,
      title: 'Monkey',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Fmonkey.png?alt=media&token=81fbdc8a-4776-45a8-ac96-d27f3042d98b'
    },
    {
      id: 9,
      title: 'Wall',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Fmonkey.png?alt=media&token=81fbdc8a-4776-45a8-ac96-d27f3042d98b'
    },
    {
      id: 10,
      title: 'Frame',
      image:
        'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/editor%2Fmonkey.png?alt=media&token=81fbdc8a-4776-45a8-ac96-d27f3042d98b'
    }
  ]
};

const getNewObjectConfig = (name, position = [0, 0, 0]) => {
  switch (name) {
    case 'Cube':
      return { position };
    case 'Sphere':
      return { transform: { position } };
    case 'Torus':
      return { position };
    default:
      return { position };
  }
};

export default function ControlPlane({ setState, selectedItem, setSelectedItem }) {
  const [displayNewObjectOptions, setDisplayNewObjectOptions] = useState(false);
  const { values, setValues } = useFormikContext();

  const addObject = (name, position, parentId = 'main') => {
    const id = nanoid();
    const newObject = {
      title: `${name} - ${id.slice(0, 6)}`,
      name: name.toLowerCase(),
      ...getNewObjectConfig(name, position)
    };
    setValues({
      ...values,
      [id]: newObject,
      [parentId]: {
        ...values[parentId],
        children: [...values[parentId].children, id]
      }
    });
    setDisplayNewObjectOptions(false);
    setSelectedItem(id);
  };

  const removeObject = (objectId) => {
    const { [objectId]: objectValue, ...rest } = values;
    let res = rest;
    for (const childId of objectValue.children) {
      const { [childId]: omit, ...newRest } = res;
      res = newRest;
    }
    const parentId = objectValue.parent || 'main';
    setState((prev) => ({
      ...res,
      [parentId]: {
        ...prev[parentId],
        children: prev[parentId].children.filter((i) => i !== objectId)
      }
    }));
  };

  const handleSubmit = () => {
    console.log(values);
  };

  // const obj = {
  //   position: 'Input3',
  //   color: 'Input',
  //   name: 'Input',
  //   camera: {
  //     position: 'Input3'
  //   },
  //   transform: {
  //     position: 'Input3',
  //     rotation: 'Input3',
  //     scale: 'Input3'
  //   },
  //   material: {
  //     visible: 'Bool'
  //   },
  //   meshStandardMaterial: {
  //     color: 'ColorPicker',
  //     roughness: 'InputNumRange.0-1'
  //   }
  // };
  // obj.meshStandardMaterial.roughness;

  const renderSingleField = (inputType, name, label) => {
    switch (inputType) {
      case 'Bool':
        return <Checkbox label={label} name={name} />;
      case 'Input':
        return <Input label={label} name={name} />;
      case 'Input3':
        return <Input3 label={label} name={name} />;
      default:
        return null;
    }
  };

  const renderFormFields = (obj, prevName, level = 0) => {
    const HeaderComponent = `h${level + 2}`;

    return Object.entries(obj).map(([key, value]) => {
      const currName = prevName === '' ? key : `${prevName}.${key}`;
      return (
        <>
          {typeof value === 'string' ? (
            renderSingleField(value, currName, key)
          ) : (
            <>
              <HeaderComponent className={level === 0 ? 'text-lg' : 'text-base'}>
                {key}
              </HeaderComponent>
              {renderFormFields(value, currName, level + 1)}
            </>
          )}
          {level === 0 && <hr className='mt-2' />}
        </>
      );
    });
  };

  return (
    <aside className='h-full border-2 border-red-400 col-span-3'>
      {selectedItem && (
        <div className='bg-gray-300 p-2'>
          Modify item{' '}
          <strong>
            <code>{values[selectedItem]?.title}</code>
          </strong>
        </div>
      )}

      <div className='p-2'>
        {selectedItem ? (
          <>
            {renderFormFields(types, selectedItem)}
            <button
              type='submit'
              className='mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
              Save
            </button>
            <hr className='mt-2' />
          </>
        ) : (
          <p>{!displayNewObjectOptions && 'Select an object to modify'}</p>
        )}

        {!selectedItem && displayNewObjectOptions ? (
          <>
            <Accordion alwaysOpen>
              {Object.entries(objectOptions).map(([key, value]) => (
                <Accordion.Panel>
                  <Accordion.Title>{key}</Accordion.Title>
                  <Accordion.Content>
                    <Cards options={value} onChildClick={addObject} />
                  </Accordion.Content>
                </Accordion.Panel>
              ))}
            </Accordion>
            <button
              type='button'
              onClick={() => {
                setDisplayNewObjectOptions(false);
              }}
              className='mt-8 text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900'>
              Cancel
            </button>
          </>
        ) : (
          <button
            type='button'
            onClick={() => {
              setDisplayNewObjectOptions(true);
              setSelectedItem(null);
            }}
            className='mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
            New object
          </button>
        )}
      </div>
    </aside>
  );
}

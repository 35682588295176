import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  faMicrophone,
  faVideo,
  faVideoSlash,
  faMicrophoneSlash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { joinMeetingAsync } from '../redux/meeting/thunks';
import { setAudio, setVideo } from '../redux/rtc/actions';

import Layout from '../components/Layout';

export default function MeetingLobby({ currentUser }) {
  const { meetingId } = useParams();

  const dispatch = useDispatch();
  const { mainStream, audio, video } = useSelector((st) => st.rtc);

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = mainStream;
      // videoRef.current.muted = !audio;
    }
  }, [videoRef, mainStream]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(joinMeetingAsync(meetingId, currentUser.uid));
  };

  const handleToggleAudio = (e) => {
    if (mainStream) {
      mainStream.getAudioTracks()[0].enabled = !audio;
    }
    dispatch(setAudio(!audio));
  };
  const handleToggleVideo = (e) => {
    if (mainStream) {
      mainStream.getVideoTracks()[0].enabled = !video;
    }
    dispatch(setVideo(!video));
  };

  return (
    <Layout>
      <div className='grid place-items-center'>
        <video ref={videoRef} autoPlay playsInline />
        <div className='flex'>
          <div
            role='option'
            aria-selected={audio}
            tabIndex='0'
            className={`meeting-icons ${audio ? 'active' : ''}`}
            data-tip={audio ? 'Mute Audio' : 'Unmute Audio'}
            onClick={handleToggleAudio}
            onKeyPress={handleToggleAudio}>
            <FontAwesomeIcon
              size='lg'
              icon={!audio ? faMicrophoneSlash : faMicrophone}
              title='Mute'
            />
          </div>
          <div
            role='option'
            aria-selected={video}
            tabIndex='0'
            className={`meeting-icons ${video ? 'active' : ''}`}
            data-tip={video ? 'Hide Video' : 'Show Video'}
            onClick={handleToggleVideo}
            onKeyPress={handleToggleAudio}>
            <FontAwesomeIcon size='lg' icon={!video ? faVideoSlash : faVideo} />
          </div>
        </div>

        <form onSubmit={handleSubmit} className='mt-12'>
          {/* <label htmlFor="username">Enter a username:</label>
          <input name="username" type="text" className="" /> */}
          <button
            type='submit'
            className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
            Ask to join
          </button>
        </form>
      </div>
    </Layout>
  );
}

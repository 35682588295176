import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { subscribeToMessagesAsync, sendNewMessageAsync } from '../../redux/meeting/thunks';

export default function Chat() {
  const { meetingId } = useParams();
  const dispatch = useDispatch();
  const {
    all: messages,
    hasSubscribed,
    isSubscribing,
    isSending,
    sendingError
  } = useSelector((st) => st.meeting.messages);
  const user = useSelector((st) => st.auth.user.data);
  const [message, setMessage] = useState('');
  const messagesEnd = useRef();
  useEffect(() => {
    if (!hasSubscribed && !isSubscribing) {
      dispatch(subscribeToMessagesAsync(meetingId));
    }
  }, [dispatch, meetingId, hasSubscribed, isSubscribing]);

  const scrollToBottom = () => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToBottom();
  });

  const containerStyles = {
    position: 'absolute',
    left: '.6rem',
    bottom: '5rem',
    minWidth: '20rem',
    maxHeight: '20rem',
    overflow: 'auto',
    zIndex: 123,
    opacity: 0.8,
    padding: '.5rem'
    // backgroundColor: "black",
    // color: "white",
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      sendNewMessageAsync(meetingId, {
        text: message,
        sender: user.uid,
        time: new Date()
      })
    );
    setMessage('');
  };

  return (
    <div className='bg-white' style={containerStyles}>
      {messages.map((m) => (
        <div>
          {m.sender.slice(0, 7)}: {m.text}
        </div>
      ))}
      {isSending ? 'sending...' : ''}
      {sendingError}
      <form onSubmit={handleSubmit} autoComplete='off'>
        <div
          ref={messagesEnd}
          className='mt-1 border-b border-gray-300 focus-within:border-indigo-600'>
          <input
            type='text'
            name='message'
            value={message}
            onChange={handleChange}
            className='block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm'
          />
        </div>
      </form>
    </div>
  );
}

import { Physics } from '@react-three/cannon';
import { OrbitControls, TransformControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import * as THREE from 'three';

import { Objects } from '../../components/three-d/_common';

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
};

function Floor({ onClick }) {
  const handleClick = (e) => {
    e.stopPropagation();
    const [x, y, z] = Object.values(e.point).map((coord) => Math.ceil(coord));
    // TODO: Figure out why this is causing an issue with original y value?
    onClick(x, y + 2.5, z);
  };
  return (
    <mesh rotation={[-Math.PI * 0.5, 0, 0]} position={[0, 0, 0]} onClick={handleClick}>
      <planeGeometry args={[20, 20]} />
      <meshStandardMaterial
        attach='material'
        color='black'
        side={THREE.DoubleSide}
        transparent
        opacity={0.0}
      />
    </mesh>
  );
}

// function Highlight() {
//   const mousePosition = new THREE.Vector2();
//   return (
//     <mesh rotation={[-Math.PI * 0.5, 0, 0]} position={[0.5, 0, 0.5]}>
//       <planeGeometry name='ground' args={[1, 1]} />
//       <meshStandardMaterial attach='material' color='white' side={THREE.DoubleSide} />
//     </mesh>
//   );
// }

function Scene() {
  const { ground, sky, lights, physics } = useSelector((st) => st.base.scene);
  const baseObjectData = useSelector((st) => st.base.objects);
  const { values } = useFormikContext();

  // const { mode } = useControls({
  //   mode: { value: 'translate', options: ['translate', 'rotate', 'scale'] }
  // });
  // const walls = useStore((state) => state.walls);
  // const walls = [
  //   { name: 'wall', position: [0, 2.5, 0] },
  //   { name: 'wall', position: [0, 2.5, 5] }
  // ];

  return (
    <main className='h-full col-span-7'>
      {/* Your content */}
      <Canvas
        camera={{
          fov: 60,
          // position: values?.main?.position,
          position: [0, 4, -15],
          rotation: [0, 0, 0],
          aspect: sizes.width / sizes.height,
          near: 0.1,
          far: 1000
        }}
        dpr={[1, 2]}
        // onPointerMissed={() => setTarget(null)}
        shadows={{ type: 'PCFSoftShadowMap' }}
        onCreated={(st) => st.gl.setClearColor('#d3d3d3')}>
        <Physics gravity={physics.gravity}>
          <ambientLight intensity={0.8} color='#ffffff' />
          {/* <directionalLight
            intensity={0.5}
            color='#ffffff'
            castShadow
            shadow-mapSize-height={256}
            shadow-mapSize-width={256}
            shadow-camera-far={7}
          /> */}
          {/* <Wall useStore={useStore} /> */}
          {/* <Sphere /> */}
          <Objects
            baseObjectData={baseObjectData}
            sceneObjects={values}
            // useStore={useStore}
          />
          {/* {target && <TransformControls object={target} mode={mode} />} */}
          <Floor />
          {/* <Highlight /> */}
        </Physics>
        <OrbitControls makeDefault />
        <gridHelper args={[20, 20]} />
      </Canvas>
    </main>
  );
}

export default Scene;

import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import Tabs from './Tabs';

export default function ActivitySearch({
  showSearchBar,
  setShowSearchBar,
  setSceneId,
  setActivityType
}) {
  return (
    <Transition.Root show={showSearchBar} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setShowSearchBar}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-0 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel className='m-8 relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mx-16 lg:w-full lg:p-6'>
                <div>
                  <Tabs
                    setSceneId={setSceneId}
                    setActivityType={setActivityType}
                    setShowSearchBar={setShowSearchBar}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { Physics } from '@react-three/cannon';
import { Canvas } from '@react-three/fiber';
import React, { Suspense, useEffect } from 'react';
import { Vector3 } from 'three';

import useStateRef from '../../../../hooks/useStateRef';
import Box from './Box';
import Camera from './Camera';

export default function Cannon() {
  const boxHeight = 1;
  const DEFAULT_STATE = [
    {
      position: [0, 0, 0],
      args: [3, boxHeight, 3],
      direction: 'z',
      active: false,
      scale: [1, 1, 1]
    },
    {
      position: [-10, 3, 0],
      args: [3, boxHeight, 3],
      direction: 'x',
      active: true,
      scale: [1, 1, 1]
    }
  ];

  const originalBoxSize = 3;

  const [, setStack, stackRef] = useStateRef(DEFAULT_STATE);
  const [, setHang] = useStateRef([]);
  const [, setTopLayerPosition, topLayerPositionRef] = useStateRef(
    new Vector3(...DEFAULT_STATE[1].pos)
  );
  const [, setPreLayerPosition, preLayerPositionRef] = useStateRef(
    new Vector3(...DEFAULT_STATE[0].pos)
  );
  // console.log(topLayerPositionRef.current, preLayerPositionRef.current);
  const [, setGameStarted, gameStartedRef] = useStateRef(false);
  const [, setGameEnded, gameEndedRef] = useStateRef(false);

  // console.log('----');
  // console.log('pre', preLayerPositionRef.current);
  // console.log('top', topLayerPositionRef.current);

  const addNewItemHandler = () => {
    const stackLength = stackRef.current.length;

    if (!gameStartedRef.current) {
      setGameStarted(true);
    } else {
      // Set prev position
      setPreLayerPosition(topLayerPositionRef.current);

      // Cut Layer
      const topLayer = stackRef.current[stackLength - 1];
      // const previousLayer = stackRef.current[stackLength - 2];

      const { direction } = topLayer;
      const size = topLayer.args[direction === 'x' ? 0 : 2];

      const delta = topLayerPositionRef.current[direction] - preLayerPositionRef.current[direction];
      const overhangSize = Math.abs(delta);
      const overlap = size - overhangSize;

      if (overlap > 0) {
        setStack((stack) =>
          stack.map((item, idx) => {
            const newWidth = direction === 'x' ? overlap : item.args[direction === 'x' ? 0 : 2];
            const newDepth = direction === 'z' ? overlap : item.args[direction === 'x' ? 0 : 2];
            const newPosition =
              direction === 'x'
                ? [
                    topLayerPositionRef.current.x - delta / 2,
                    topLayerPositionRef.current.y,
                    topLayerPositionRef.current.z
                  ]
                : [
                    topLayerPositionRef.current.x,
                    topLayerPositionRef.current.y,
                    topLayerPositionRef.current.z - delta / 2
                  ];

            const newScale = direction === 'x' ? [overlap / size, 1, 1] : [1, 1, overlap / size];

            return idx === stack.length - 1
              ? {
                  ...item,
                  position: newPosition,
                  args: [newWidth, boxHeight, newDepth],
                  active: false,
                  scale: newScale
                }
              : item;
          })
        );

        //   Overhang
        const overhangShift = (overlap / 2 + overhangSize / 2) * Math.sign(delta);
        const overhangX =
          direction === 'x'
            ? topLayerPositionRef.current.x + overhangShift
            : topLayerPositionRef.current.x;
        const overhangZ =
          direction === 'z'
            ? topLayerPositionRef.current.z + overhangShift
            : topLayerPositionRef.current.z;
        // console.log(overhangZ);

        const overhangWidth = direction === 'x' ? overhangSize : topLayer.args[0];
        const overhangDepth = direction === 'z' ? overhangSize : topLayer.args[1];

        const yHang = boxHeight * (stackLength - 1); // Add the new box one the same layer

        const newHangItem = {
          position: [overhangX, yHang, overhangZ],
          args: [overhangWidth, boxHeight, overhangDepth]
        };
        // console.log(newHangItem);
        setHang((hang) => [...hang, newHangItem]);
      } else {
        setGameEnded(true);
      }
    }

    // Add new layer
    const lastItem = stackRef.current[stackLength - 1];
    const prevDirection = lastItem.direction;
    const nextX = prevDirection === 'x' ? 0 : -10;
    const nextZ = prevDirection === 'z' ? 0 : -10;
    const nextWidth = originalBoxSize;
    const nextDepth = originalBoxSize;
    const nextDirection = prevDirection === 'x' ? 'z' : 'x';

    const y = boxHeight * stackLength;
    // const layer = generateBox(x, y, z, width, depth);
    const newItem = {
      position: [nextX, y, nextZ],
      args: [nextWidth, boxHeight, nextDepth],
      direction: nextDirection,
      active: true
    };

    setStack((stack) => [...stack, newItem]);
    setTopLayerPosition(new Vector3(nextX, y, nextZ));
  };

  useEffect(() => {
    document.querySelector('canvas').addEventListener('click', addNewItemHandler);
  }, []);

  if (gameStartedRef.current && gameEndedRef.current) {
    return <h1 className='text-center'>GAME OVER!!!</h1>;
  }

  return (
    <Suspense fallback={null}>
      <span className='text-4xl absolute bottom-5 right-3 text-white z-50'>
        {Math.max(topLayerPositionRef.current.y - 1, 0)}
      </span>
      <Canvas>
        <Camera stackL={stackRef.current.length} />
        <Physics>
          <color attach='background' args={['black']} />
          {/* <fog attach="fog" args={["#191920", 0, 15]} /> */}
          <ambientLight intensity={0.6} color='white' />
          <directionalLight color='white' intensity={0.6} position={[10, 20, 0]} />
          {stackRef.current.map((data, idx) => (
            <Box
              // eslint-disable-next-line
              key={idx}
              {...data}
              isTopItem={idx === stackRef.current.length - 1}
              setTopLayerPosition={setTopLayerPosition}
            />
          ))}
        </Physics>
      </Canvas>
    </Suspense>
  );
}

import {
  FETCH_USER_FAILURE,
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  SIGN_IN_ANON_START,
  SIGN_IN_ANON_SUCCESS,
  SIGN_IN_ANON_FAILURE,
  SIGN_IN_WITH_GOOGLE_START,
  SIGN_IN_WITH_GOOGLE_SUCCESS,
  SIGN_IN_WITH_GOOGLE_FAILURE
} from './types';

const INITIAL_STATE = {
  user: {
    data: null,
    error: null,
    isLoading: false
  },
  signIn: {
    error: null,
    isLoading: false,
    success: false
  },
  signOut: {
    error: null,
    isLoading: false,
    success: false
  }
};

const authReducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_USER_START:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true
        }
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          data: payload.user,
          isLoading: false
        }
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          error: payload.errorMessage,
          isLoading: false
        }
      };

    case SIGN_IN_ANON_START:
      return {
        ...state,
        isLoading: true
      };
    case SIGN_IN_ANON_SUCCESS:
      return {
        ...state,
        data: payload.user,
        error: null,
        isLoading: false
      };
    case SIGN_IN_ANON_FAILURE:
      return {
        ...state,
        data: null,
        error: payload.errorMessage,
        isLoading: false
      };

    case SIGN_IN_WITH_GOOGLE_START:
      return {
        ...state,
        signIn: {
          error: null,
          isLoading: true,
          success: false
        }
      };
    case SIGN_IN_WITH_GOOGLE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          data: payload.user
        },
        signIn: {
          error: null,
          isLoading: false,
          success: true
        }
      };
    case SIGN_IN_WITH_GOOGLE_FAILURE:
      return {
        ...state,
        signIn: {
          error: payload.errorMessage,
          isLoading: false,
          success: false
        }
      };

    default:
      return state;
  }
};

export default authReducer;

import { Navigate, Route, Routes } from 'react-router-dom';

import CreateMeeting from './pages/CreateMeeting';
import Dashboard from './pages/Dashboard';
import DesignSystem from './pages/DesignSystem';
import Editor from './pages/Editor';
import Home from './pages/Home';
import Login from './pages/Login';
import Meeting from './pages/Meeting';
import PrivateRoute from './pages/_PrivateRoute';

// import NotFound from "./pages/NotFound";

function App() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path='login' element={<Login />} />
      <Route path='editor' element={<Editor />} />
      <Route path='meeting/:meetingId' element={<Meeting />} />
      <Route path='design-system' element={<DesignSystem />} />

      {/* <Route path="*" element={<NotFound />} /> */}
      <Route element={<PrivateRoute />}>
        <Route path='meeting/new' element={<CreateMeeting />} />
        <Route path='dashboard' element={<Dashboard />} />
      </Route>
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
}

export default App;

import { SET_MAIN_STREAM, SET_AUDIO, SET_VIDEO } from './types';

export const setMainStream = (stream) => ({
  type: SET_MAIN_STREAM,
  payload: {
    mainStream: stream
  }
});

export const setAudio = (audio) => ({
  type: SET_AUDIO,
  payload: { audio }
});

export const setVideo = (video) => ({
  type: SET_VIDEO,
  payload: { video }
});

import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { Button, Modal } from 'flowbite-react';
import { useEffect, useState } from 'react';

import { getDocByCollectionPath, getDocsByCollectionPath } from '../../firebase/firestore';
import { classNames } from '../../utils';

export default function TemplateSelector({ setInitialFormValues, open, setOpen }) {
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [templateOptionsLoading, setTemplateOptionsLoading] = useState(true);
  const [templateOptions, setTemplateOptions] = useState([]);

  useEffect(() => {
    async function loadTemplateOptions() {
      setTemplateOptionsLoading(true);
      const templateOptionsRes = await getDocsByCollectionPath('templateMetadata');
      setTemplateOptions(templateOptionsRes);
      setTemplateOptionsLoading(false);
    }
    loadTemplateOptions();
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const templateDataRes = await getDocByCollectionPath('templateData', selectedTemplateId);
    if (templateDataRes) {
      setInitialFormValues(templateDataRes.data);
    }
    onClose();
  };

  return (
    <>
      {/* <Button onClick={onClick}>Toggle modal</Button> */}
      <Modal show={open} onClose={onClose}>
        <Modal.Header>Select a template to start with</Modal.Header>
        <Modal.Body>
          {templateOptionsLoading && <div>Loading...</div>}
          <RadioGroup value={selectedTemplateId} onChange={setSelectedTemplateId}>
            {/* <RadioGroup.Label></RadioGroup.Label> */}
            <div className='mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4'>
              {templateOptions.map((template) => (
                <RadioGroup.Option
                  key={template.key}
                  value={template.key}
                  className={({ checked, active }) =>
                    classNames(
                      checked ? 'border-transparent' : 'border-gray-300',
                      active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                      'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                    )
                  }>
                  {({ checked, active }) => (
                    <>
                      <span className='flex-1 flex'>
                        <span className='flex flex-col'>
                          <RadioGroup.Label
                            as='span'
                            className='block text-sm font-medium text-gray-900'>
                            {template.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as='span'
                            className='mt-1 flex items-center text-sm text-gray-500'>
                            <img src={template.imageUrl} alt={template.imageAlt || ''} />
                          </RadioGroup.Description>
                        </span>
                      </span>
                      <CheckCircleIcon
                        className={classNames(
                          !checked ? 'invisible' : '',
                          'h-5 w-5 text-indigo-600'
                        )}
                        aria-hidden='true'
                      />
                      <span
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked ? 'border-indigo-500' : 'border-transparent',
                          'absolute -inset-px rounded-lg pointer-events-none'
                        )}
                        aria-hidden='true'
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit}>Select</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

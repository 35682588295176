import { useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { useRef } from 'react';

export default function AvatarPreview() {
  const group = useRef();

  const { nodes, materials } = useGLTF(
    'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/avatars%2FSoldier.glb?alt=media&token=22cedb73-c3c9-496b-982d-f75b8a24ab8f'
  );

  return (
    <Canvas>
      <group ref={group} dispose={null}>
        <group rotation={[-Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh
            geometry={nodes.vanguard_Mesh.geometry}
            material={materials.VanguardBodyMat}
            skeleton={nodes.vanguard_Mesh.skeleton}
          />
          <skinnedMesh
            geometry={nodes.vanguard_visor.geometry}
            material={materials.Vanguard_VisorMat}
            skeleton={nodes.vanguard_visor.skeleton}
          />
        </group>
      </group>
    </Canvas>
  );
}

export const PLANE_COMPONENT_TYPES = {
  planeBufferGeometry: 'planeBufferGeometry',
  planeGeometry: 'planeGeometry'
};

export const MESH_COMPONENT_TYPES = {
  MeshReflectorMaterial: 'MeshReflectorMaterial',
  MeshDistortMaterial: 'MeshDistortMaterial',
  MeshWobbleMaterial: 'MeshWobbleMaterial',
  meshStandardMaterial: 'meshStandardMaterial'
};

import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  endAt,
  endBefore,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt, // serverTimestamp,
  // Timestamp
  // increment
  // arrayUnion,
  // arrayRemove
  updateDoc,
  where
} from 'firebase/firestore';

import firebaseApp from './init';

const db = getFirestore(firebaseApp);

export const addDocByCollectionPath = async (path, data) => {
  try {
    const res = await addDoc(collection(db, path), data);
    return res.id;
    // console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    // console.error('Error adding document: ', e);
  }
};

// If the document does not exist, it will be created.
// If the document does exist, its contents will be overwritten, or will be merged if `merge` is true
export const setDocByPathAndId = async (path, id, data, merge = false) => {
  try {
    const docRef = doc(db, path, id);

    await setDoc(docRef, data, { merge });

    // console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    // console.error('Error adding document: ', e);
  }
};

export const getDocByCollectionPath = async (coll, docId) => {
  console.log(coll);
  console.log(docId);
  try {
    const docSnapshot = await getDoc(doc(db, coll, docId));
    return docSnapshot.exists() ? docSnapshot.data() : null;
  } catch (e) {
    console.error('Error getDocByPath: ', e);
    return null;
  }
};

export const getDocsByCollectionPath = async (path) => {
  try {
    const docsSnapshot = await getDocs(collection(db, path));
    const result = [];
    docsSnapshot.forEach((d) => {
      result.push({ ...d.data(), key: d.id });
      console.log(`${d.id} => ${d.data()}`);
    });
    return result;
  } catch (e) {
    console.error('Error getDocsByPath: ', e);
    return [];
  }
};

export const getNewDocRefByCollectionPath = (path) => doc(collection(db, path));

export const setDocByRef = async (ref, data) => {
  try {
    await setDoc(ref, data);
  } catch (err) {
    console.error(err);
  }
};

export const updateDocByPathAndId = async (path, id, overrideData) => {
  try {
    const docRef = doc(db, path, id);
    const res = await updateDoc(docRef, overrideData);
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};


// WARNING: Deleting a document does not delete its subcollections!
// For more details, see https://firebase.google.com/docs/firestore/manage-data/delete-data#delete_documents
export const deleteDocByPathAndId = async (path, id) => {
  try {
    const docRef = doc(db, path, id);
    await deleteDoc(docRef);
  } catch (err) {
    // console.error(err);
  }
};

export const deleteFields = async (path, id, fields) => {
  const docRef = doc(db, path, id);

  const filedToBeDeleted = {};
  for (const field of fields) {
    filedToBeDeleted[field] = deleteField();
  }

  await updateDoc(docRef, filedToBeDeleted);
};

export const getAllDocsByCollectionPath = async (path) => {
  try {
    const querySnapshot = await getDocs(collection(db, path));
    return querySnapshot.docs.map((d) => ({ id: d.id, ...d.data() }));
  } catch (err) {
    // console.error(err);
    return undefined;
  }
};

// Realtime updates
export const onSnapshotByPathAndId = (path, id, callback, errorCallback) => {
  const docRef = doc(db, path, id);

  const unsubscribe = onSnapshot(
    docRef,
    (d) => callback(d.data()),
    (err) => (errorCallback ? errorCallback(err) : console.error(err))
  );
  return unsubscribe;
};

/**  Queries
 * QueryType: "where" | "orderBy" | "limit"
 * @param {string} path
 * @param {Array<Array<QueryType, ...args>>} queries: [["state","==","CA"],["population", "<", 1000000]]
  [
    ["where", "state", "==", "CA"],
    ["orderBy", "population", "desc"],
    ["limit", 10],
  ]
* */
export const queryDocsByCollectionPath = async (path, queries) => {
  try {
    const colRef = collection(db, path);
    const q = query(
      colRef,
      ...queries.map(([type, ...args]) => {
        switch (type) {
          case 'where':
            return where(...args);
          case 'orderBy':
            return orderBy(...args);
          case 'limit':
            return limit(args);
          case 'startAt':
            return startAt(...args);
          case 'startAfter':
            return startAfter(...args);
          case 'endAt':
            return endAt(args);
          case 'endBefore':
            return endBefore(args);
          default:
            return where(...args);
        }
      })
    );

    return await getDocs(q);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

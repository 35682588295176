import {
  FETCH_MEETING_DATA_START,
  FETCH_MEETING_DATA_SUCCESS,
  FETCH_MEETING_DATA_FAILURE,
  CREATE_MEETING_START,
  CREATE_MEETING_SUCCESS,
  CREATE_MEETING_FAILURE,
  CREATE_NEW_OBJECT_START,
  CREATE_NEW_OBJECT_SUCCESS,
  CREATE_NEW_OBJECT_FAILURE,
  REMOVE_OBJECT_START,
  REMOVE_OBJECT_SUCCESS,
  REMOVE_OBJECT_FAILURE,

  // RTC
  INITIALIZE_LISTENERS_SUCCESS,
  JOIN_TO_PARTICIPANTS_SUCCESS,
  SUBSCRIBE_TO_PARTICIPANTS_START,
  SUBSCRIBE_TO_PARTICIPANTS_SUCCESS,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT,
  UPDATE_PARTICIPANT,
  SUBSCRIBE_TO_OBJECTS_START,
  SUBSCRIBE_TO_OBJECTS_SUCCESS,
  ADD_OBJECT,
  UPDATE_OBJECT,
  REMOVE_OBJECT,
  UPDATE_RTC_PREFERENCES,

  // Messages
  SUBSCRIBE_TO_MESSAGES_SUCCESS,
  SUBSCRIBE_TO_MESSAGES_START,
  ADD_MESSAGE,
  SEND_NEW_MESSAGE_START,
  SEND_NEW_MESSAGE_SUCCESS,
  SEND_NEW_MESSAGE_FAILURE
} from './types';

export const fetchMeetingDataStart = () => ({
  type: FETCH_MEETING_DATA_START
});

export const fetchMeetingDataSuccess = (id, meeting) => ({
  type: FETCH_MEETING_DATA_SUCCESS,
  payload: { id, meeting }
});

export const fetchMeetingDataFailure = (error) => ({
  type: FETCH_MEETING_DATA_FAILURE,
  payload: { error }
});

export const createMeetingStart = () => ({
  type: CREATE_MEETING_START
});

export const createMeetingSuccess = (meetingId) => ({
  type: CREATE_MEETING_SUCCESS,
  payload: { meetingId }
});

export const createMeetingFailure = (error) => ({
  type: CREATE_MEETING_FAILURE,
  payload: { error }
});

export const createNewObjectStart = () => ({
  type: CREATE_NEW_OBJECT_START
});

export const createNewObjectSuccess = (objectKey, objectValue) => ({
  type: CREATE_NEW_OBJECT_SUCCESS,
  payload: { objectKey, objectValue }
});

export const createNewObjectFailure = (error) => ({
  type: CREATE_NEW_OBJECT_FAILURE,
  payload: { error }
});

export const removeObjectStart = () => ({
  type: REMOVE_OBJECT_START
});

export const removeObjectSuccess = (objectKey) => ({
  type: REMOVE_OBJECT_SUCCESS,
  payload: { objectKey }
});

export const removeObjectFailure = (error) => ({
  type: REMOVE_OBJECT_FAILURE,
  payload: { error }
});

// RTC

export const initializeListenersSuccess = () => ({
  type: INITIALIZE_LISTENERS_SUCCESS
});

export const joinToParticipantsSuccess = () => ({
  type: JOIN_TO_PARTICIPANTS_SUCCESS
});

export const subscribeToParticipantsStart = () => ({
  type: SUBSCRIBE_TO_PARTICIPANTS_START
});

export const subscribeToParticipantsSuccess = () => ({
  type: SUBSCRIBE_TO_PARTICIPANTS_SUCCESS
});

export const addParticipant = (participantKey, participantValue) => ({
  type: ADD_PARTICIPANT,
  payload: {
    participantKey,
    participantValue
  }
});

export const updateParticipant = (participantKey, overrideData) => ({
  type: UPDATE_PARTICIPANT,
  payload: {
    participantKey,
    overrideData
  }
});

export const removeParticipant = (participantKey) => ({
  type: REMOVE_PARTICIPANT,
  payload: {
    participantKey
  }
});

export const subscribeToObjectsStart = () => ({
  type: SUBSCRIBE_TO_OBJECTS_START
});

export const subscribeToObjectsSuccess = () => ({
  type: SUBSCRIBE_TO_OBJECTS_SUCCESS
});

export const addNewObject = (objectKey, objectValue) => ({
  type: ADD_OBJECT,
  payload: {
    objectKey,
    objectValue
  }
});

export const updateObject = (objectKey, objectValue) => ({
  type: UPDATE_OBJECT,
  payload: {
    objectKey,
    objectValue
  }
});

export const removeObject = (objectKey) => ({
  type: REMOVE_OBJECT,
  payload: {
    objectKey
  }
});

export const updateRTCPreferencesSuccess = (meetingId, userId, newPreferences) => ({
  type: UPDATE_RTC_PREFERENCES,
  payload: {
    meetingId,
    userId,
    newPreferences
  }
});

// MESSAGES
export const subscribeToMessagesStart = () => ({
  type: SUBSCRIBE_TO_MESSAGES_START
});

export const subscribeToMessagesSuccess = () => ({
  type: SUBSCRIBE_TO_MESSAGES_SUCCESS
});

export const addNewMessage = (key, value) => ({
  type: ADD_MESSAGE,
  payload: {
    key,
    value
  }
});

export const sendNewMessageStart = () => ({
  type: SEND_NEW_MESSAGE_START
});

export const sendNewMessageSuccess = () => ({
  type: SEND_NEW_MESSAGE_SUCCESS
});

export const sendNewMessageFailure = (key, value) => ({
  type: SEND_NEW_MESSAGE_FAILURE
});

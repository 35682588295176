import { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getDocByCollectionPath } from '../../firebase/firestore';
import ActivitySearch from './ActivitySearch';
import AvatarSelector from './AvatarSelector';
import Canvas from './canvas/Canvas';
import ControlPlane from './control-plane';
import Game from './games/cannon';
import Video360 from './video-360';
import Whiteboard from './whiteboard';

const ACTIVITY_OPTIONS = {
  canvas: 'canvas',
  game: 'game',
  video360: 'video360',
  whiteboard: 'whiteboard'
};

export default function RoomActivity() {
  const user = useSelector((st) => st.auth.user.data);

  const [activityType, setActivityType] = useState(ACTIVITY_OPTIONS.canvas);
  // Gallery: "nsGfyIHhyP6oo3VKx7iz"
  // Minecraft: "Mmf9vJkeOuwGsbdqMn4O"
  const [sceneId, setSceneId] = useState();
  const [sceneData, setSceneData] = useState();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAvatarSelector, setShowAvatarSelector] = useState(false);

  useEffect(() => {
    const getSceneData = async () => {
      // const data = await getDocByCollectionPath('scenes', sceneId);
      // setSceneData(data);
    };
    getSceneData();
  }, [sceneId]);

  useEffect(() => {
    if (!user?.avatarConfig) {
      setShowAvatarSelector(true);
    }
  }, []);

  const renderScene = () => {
    switch (activityType) {
      case ACTIVITY_OPTIONS.canvas:
        return <Canvas user={user} sceneId={sceneId} sceneData={sceneData} />;
      case ACTIVITY_OPTIONS.video360:
        return <Video360 videoId={sceneId} />;
      case ACTIVITY_OPTIONS.game:
        return <Game />;
      case ACTIVITY_OPTIONS.whiteboard:
        return <Whiteboard />;
      default:
        return null;
    }
  };

  return (
    <Suspense fallback={null}>
      {user?.avatarConfig ? (
        <>
          {renderScene(activityType)}
          <ControlPlane
            setActivityType={setActivityType}
            setSceneId={setSceneId}
            setShowSearchBar={setShowSearchBar}
          />
          {/* Display when there is no scene chosen for the selected activity type or when showSearchBar is true */}
          {(activityType && !sceneId) || showSearchBar ? (
            <ActivitySearch
              showSearchBar={showSearchBar}
              setShowSearchBar={setShowSearchBar}
              setSceneId={setSceneId}
              setActivityType={setActivityType}
            />
          ) : null}
        </>
      ) : (
        <AvatarSelector
          setShowAvatarSelector={setShowAvatarSelector}
          showAvatarSelector={showAvatarSelector}
        />
      )}
    </Suspense>
  );
}

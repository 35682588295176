import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chat from '../components/meeting/Chat';
import MainRTC from '../components/rtc/MainRTC';
import RoomActivity from '../components/three-d/ThreeD';
import { signInAnonAsync } from '../redux/auth/thunks';
import { setMainStream } from '../redux/rtc/actions';
import MeetingLobby from './MeetingLobby';

function Meeting() {
  const dispatch = useDispatch();

  const {
    get: { data: meeting, isLoading: meetingIsLoading, error: meetingNotFound }
  } = useSelector((st) => st.meeting);
  const user = useSelector((st) => st.auth.user.data);
  const currentStream = useSelector((st) => st.rtc.mainStream);

  useEffect(() => {
    if (!user) {
      dispatch(signInAnonAsync());
    }
  }, [dispatch, user]);

  useEffect(() => {
    const getUserStream = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      });
      stream.getVideoTracks()[0].enabled = false;
      stream.getAudioTracks()[0].enabled = false;
      dispatch(setMainStream(stream));
    };

    if (!currentStream) {
      getUserStream();
    }
  }, [dispatch, currentStream]);

  if (!user) {
    return <div>Authentication error</div>;
  }

  if (!meeting) {
    if (meetingIsLoading) {
      return <div>Loading...</div>;
    }
    if (meetingNotFound) {
      return <div>Invalid meeting ID</div>;
    }
    return <MeetingLobby currentUser={user} />;
  }

  return (
    <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <RoomActivity />
      <MainRTC />
      <Chat />
    </div>
  );
}

export default Meeting;

import { RadioGroup } from '@headlessui/react';
import { useField } from 'formik';

import { classNames } from '../../utils';
import TextError from './TextError';

const COLOR_OPTIONS = [
  { id: 'pink', label: 'Pink', bgColor: 'bg-pink-500', selectedColor: 'ring-pink-500' },
  { id: 'purple', label: 'Purple', bgColor: 'bg-purple-500', selectedColor: 'ring-purple-500' },
  { id: 'blue', label: 'Blue', bgColor: 'bg-blue-500', selectedColor: 'ring-blue-500' },
  { id: 'green', label: 'Green', bgColor: 'bg-green-500', selectedColor: 'ring-green-500' },
  { id: 'yellow', label: 'Yellow', bgColor: 'bg-yellow-500', selectedColor: 'ring-yellow-500' }
];

export default function ColorPicker({ label, name, options = COLOR_OPTIONS }) {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  return (
    <div>
      <RadioGroup value={value} onChange={setValue}>
        <RadioGroup.Label className='block text-sm font-medium text-gray-700'>
          {label}
        </RadioGroup.Label>
        <div className='mt-4 flex items-center space-x-3'>
          {options.map((option) => (
            <RadioGroup.Option
              key={option}
              value={option}
              className={({ active, checked }) =>
                classNames(
                  option.selectedColor,
                  active && checked ? 'ring ring-offset-1' : '',
                  !active && checked ? 'ring-2' : '',
                  '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                )
              }>
              {/* <RadioGroup.Label as='p' className='sr-only'>
                {option}
              </RadioGroup.Label> */}
              <span
                aria-hidden='true'
                style={{ backgroundColor: option }}
                className={classNames('h-8 w-8 border border-black border-opacity-10 rounded-full')}
              />
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {meta.touched && meta.error ? <TextError errorMessage={meta.error} /> : null}
    </div>
  );
}

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCwXHPqnCycESp1QHgsJYukmF__bo2u9q8',
  authDomain: 'metavis-ee143.firebaseapp.com',
  databaseURL: 'https://metavis-ee143-default-rtdb.firebaseio.com',
  projectId: 'metavis-ee143',
  storageBucket: 'metavis-ee143.appspot.com',
  messagingSenderId: '755937117954',
  appId: '1:755937117954:web:3c0997f2486938c0bd641b',
  measurementId: 'G-YP1PDT9308'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;

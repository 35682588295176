import React from 'react';

export default function Video360({ videoId }) {
  return (
    <iframe
      width='100%'
      height='100%'
      src={`https://www.youtube.com/embed/${videoId}?controls=0&rel=0&autoplay=1`}
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    />
  );
}

import React, { Fragment, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { classNames } from '../../utils';

const MENU_OPTIONS_SIGNED_IN = [
  { to: '/dashboard', label: 'Dashboard' },
  { to: '/calendar', label: 'Calendar' }
];
const MENU_OPTIONS_SIGNED_OUT = [
  { to: '/marketplace', label: 'Marketplace' },
  { to: '/calendar', label: 'Calendar' }
];
const PROFILE_DROPDOWN = [
  { to: '/profile', label: 'Your Profile' },
  { to: '/settings', label: 'Settings' },
  { to: '/signout', label: 'Sign out' }
];

export default function Header() {
  const user = useSelector((st) => st.auth.user.data);
  const [MENU_OPTIONS] = useState(user ? MENU_OPTIONS_SIGNED_IN : MENU_OPTIONS_SIGNED_OUT);

  return (
    <Disclosure as='nav' className='bg-white shadow'>
      {({ open }) => (
        <>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex justify-between h-16'>
              <div className='flex'>
                <div className='-ml-2 mr-2 flex items-center md:hidden'>
                  {/* Mobile menu button */}
                  <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
                <div className='flex-shrink-0 flex items-center'>
                  <Link to='/'>
                    <img
                      className='block lg:hidden h-8 w-auto'
                      src='https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg'
                      alt='Workflow'
                    />
                    <img
                      className='hidden lg:block h-8 w-auto'
                      src='https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg'
                      alt='Workflow'
                    />
                  </Link>
                </div>
                <div className='hidden md:ml-6 md:flex md:space-x-8'>
                  {MENU_OPTIONS.map(({ to, label }) => (
                    <NavLink
                      key={to}
                      className={({ isActive }) =>
                        classNames(
                          'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                          isActive
                            ? 'border-indigo-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                      to={to}>
                      {label}
                    </NavLink>
                  ))}
                </div>
              </div>

              <div className='flex items-center'>
                {user ? (
                  <div className='hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center'>
                    <Link
                      to='/notifications'
                      className='bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                      <span className='sr-only'>View notifications</span>
                      <BellIcon className='h-6 w-6' aria-hidden='true' />
                    </Link>

                    {/* Profile dropdown */}
                    <Menu as='div' className='ml-3 relative'>
                      <div>
                        <Menu.Button className='bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                          <span className='sr-only'>Open user menu</span>
                          <img className='h-8 w-8 rounded-full' src={user.photoURL} alt='' />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items
                          className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                          style={{ zIndex: 1 }}>
                          {PROFILE_DROPDOWN.map(({ to, label }) => (
                            <Menu.Item key={to}>
                              <NavLink
                                to={to}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )
                                }>
                                {label}
                              </NavLink>
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                ) : (
                  <div className='hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center'>
                    <Link
                      to='/login'
                      className='whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900'>
                      Sign in
                    </Link>

                    <Link
                      to='/login'
                      className='ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700'>
                      Sign up
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className='md:hidden'>
            <div className='pt-2 pb-3 space-y-1'>
              {MENU_OPTIONS.map(({ to, label }) => (
                <Disclosure.Button
                  as={NavLink}
                  key={to}
                  to={to}
                  className={({ isActive }) =>
                    classNames(
                      'block px-4 py-2 text-base font-medium bg-white',
                      isActive
                        ? 'bg-white border-indigo-500 text-indigo-700'
                        : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                    )
                  }>
                  {label}
                </Disclosure.Button>
              ))}
            </div>
            <div className='pt-4 pb-3 border-t border-gray-200'>
              <div className='flex items-center px-4 sm:px-6'>
                <div className='flex-shrink-0'>
                  <img
                    className='h-10 w-10 rounded-full'
                    src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                    alt=''
                  />
                </div>
                <div className='ml-3'>
                  <div className='text-base font-medium text-gray-800'>Tom Cook</div>
                  <div className='text-sm font-medium text-gray-500'>tom@example.com</div>
                </div>
                <button
                  type='button'
                  className='ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>
              <div className='mt-3 space-y-1'>
                {PROFILE_DROPDOWN.map(({ to, label }) => (
                  <Disclosure.Button
                    as={Link}
                    key={to}
                    to={to}
                    className='block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6'>
                    {label}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

import { onDisconnect } from 'firebase/database';
import {
  pushAndSetByPath,
  getRef,
  getByPath,
  deleteByPath,
  pushByPath,
  setByPath,
  onChildAddedByPath,
  onChildRemovedByPath,
  onChildChangedByPath,
  updateByPath
} from '../../firebase/database';
import store from '../store';
import {
  fetchMeetingDataStart,
  fetchMeetingDataSuccess,
  fetchMeetingDataFailure,
  createMeetingStart,
  createMeetingSuccess,
  createMeetingFailure,
  createNewObjectStart,
  createNewObjectSuccess,
  createNewObjectFailure,
  removeObjectStart,
  removeObjectSuccess,
  removeObjectFailure,
  // RTC
  updateRTCPreferencesSuccess,
  subscribeToObjectsStart,
  addNewObject,
  updateObject,
  removeObject,
  subscribeToObjectsSuccess,
  subscribeToParticipantsStart,
  addParticipant,
  updateParticipant,
  removeParticipant,
  subscribeToParticipantsSuccess,
  initializeListenersSuccess,
  joinToParticipantsSuccess,
  subscribeToMessagesStart,
  subscribeToMessagesSuccess,
  sendNewMessageStart,
  sendNewMessageSuccess,
  sendNewMessageFailure,
  addNewMessage
} from './actions';

const SERVERS = {
  iceServers: [
    {
      urls: [
        'stun:stun1.l.google.com:19302',
        'stun:stun2.l.google.com:19302'
        // "stun:stun.l.google.com:19302",
        // "stun:stun3.l.google.com:19302",
        // "stun:stun4.l.google.com:19302",
        // "stun:stun.services.mozilla.com",
      ]
    }
  ],
  iceCandidatePoolSize: 10
};
export const fetchMeetingDataAsync = (meetingId) => async (dispatch) => {
  try {
    dispatch(fetchMeetingDataStart(meetingId));
    const resp = await getByPath(`/meetings/${meetingId}`);
    dispatch(fetchMeetingDataSuccess(meetingId, resp));
  } catch (error) {
    dispatch(fetchMeetingDataFailure(error.message));
  }
};

export const createMeetingAsync = (config) => async (dispatch) => {
  try {
    dispatch(createMeetingStart());
    const meetingId = await pushAndSetByPath('meetings', config);
    dispatch(createMeetingSuccess(meetingId));
  } catch (error) {
    dispatch(createMeetingFailure(error.message));
  }
};

export const createNewObjectAsync = (meetingId, objectConfig) => async (dispatch) => {
  try {
    dispatch(createNewObjectStart());
    const objectId = await pushAndSetByPath(`meetings/${meetingId}/objects`, objectConfig);
    dispatch(createNewObjectSuccess(objectId, objectConfig));
  } catch (error) {
    dispatch(createNewObjectFailure(error.message));
  }
};

export const removeObjectAsync = (meetingId, objectKey) => async (dispatch) => {
  try {
    dispatch(removeObjectStart());
    await deleteByPath(`meetings/${meetingId}/objects/${objectKey}`);
    dispatch(removeObjectSuccess(objectKey));
  } catch (error) {
    dispatch(removeObjectFailure(error.message));
  }
};

export const joinMeetingAsync = (meetingId, userId) => async (dispatch) => {
  try {
    const participantsPath = `meetings/${meetingId}/participants`;
    const currentUserPath = `${participantsPath}/${userId}`;

    const participantIdPath = `meetings/${meetingId}/participantIds/${userId}`;

    await Promise.all([
      // Add current user to meeting participants on DB
      dispatch(joinToParticipantsAsync(currentUserPath, participantIdPath)),
      // Initialize listeners
      dispatch(initializeOfferAnswerListenersAsync(meetingId, userId, currentUserPath)),
      // Subscribe to participants
      dispatch(subscribeToParticipantsAsync(meetingId, participantsPath))
    ]);

    // Fetch meeting data
    dispatch(fetchMeetingDataAsync(meetingId));
  } catch (err) {
    console.log(err);
  }
};

const joinToParticipantsAsync = (participantPath, participantIdPath) => async (dispatch) => {
  const newParticipant = {
    rtc: {
      audio: store.getState().rtc.audio,
      video: store.getState().rtc.video,
      screen: false
    },
    three: {
      position: [0, 3, 10],
      speed: 6,
      playerPhysics: {
        mass: 1,
        type: 'Dynamic'
      }
    }
  };
  const participantRef = getRef(participantPath);
  onDisconnect(participantRef).remove();
  await setByPath(participantPath, newParticipant);

  const participantIdRef = getRef(participantIdPath);
  onDisconnect(participantIdRef).remove();
  await setByPath(participantIdPath, true);

  return dispatch(joinToParticipantsSuccess());
};

// Used in joinToParticipantsAsync
const initializeOfferAnswerListenersAsync =
  (meetingId, currentUserId, currentUserPath) => async (dispatch) => {
    const getPeerConnection = (userId) =>
      store.getState().meeting.participants.byId[userId]?.peerConnection;

    const offersPath = `${currentUserPath}/offers`;
    const newOfferCallback = async (snapshot) => {
      const data = snapshot.val();
      const pc = getPeerConnection(data.userId);
      if (pc) {
        await pc.setRemoteDescription(new RTCSessionDescription(data));
        await createAnswer(meetingId, data.userId, currentUserId);
      }
    };
    onChildAddedByPath(offersPath, newOfferCallback);

    const offerCandidatesPath = `${currentUserPath}/offerCandidates`;
    const newOfferCandidateCallback = async (snapshot) => {
      const data = snapshot.val();
      const pc = getPeerConnection(data.userId);
      if (pc) {
        pc.addIceCandidate(new RTCIceCandidate(data));
      }
    };
    onChildAddedByPath(offerCandidatesPath, newOfferCandidateCallback);

    const answersPath = `${currentUserPath}/answers`;
    const newAnswerCallback = async (snapshot) => {
      const data = snapshot.val();
      const pc = getPeerConnection(data.userId);
      if (pc) {
        const answerDescription = new RTCSessionDescription(data);
        pc.setRemoteDescription(answerDescription);
      }
    };
    onChildAddedByPath(answersPath, newAnswerCallback);

    const answerCandidatesPath = `${currentUserPath}/answerCandidates`;
    const newAnswerCandidateCallback = async (snapshot) => {
      const data = snapshot.val();
      const pc = getPeerConnection(data.userId);
      if (pc) {
        pc.addIceCandidate(new RTCIceCandidate(data));
      }
    };
    onChildAddedByPath(answerCandidatesPath, newAnswerCandidateCallback);

    return dispatch(initializeListenersSuccess());
  };

// Used in joinToParticipantsAsync
const subscribeToParticipantsAsync = (meetingId, participantsPath) => async (dispatch) => {
  dispatch(subscribeToParticipantsStart());

  onChildAddedByPath(participantsPath, (snap) => {
    const currentUserKey = store.getState().auth.user.data.uid;
    const newParticipantKey = snap.key;
    const newParticipantValue = snap.val();
    const stream = store.getState().rtc.mainStream;

    const isParticipantInStore = !!store.getState().meeting.participants.byId[newParticipantKey];
    if (!isParticipantInStore) {
      dispatch(
        addParticipantAsync(
          meetingId,
          currentUserKey,
          stream,
          newParticipantKey,
          newParticipantValue
        )
      );
    }
  });

  onChildChangedByPath(participantsPath, (snap) => {
    dispatch(updateParticipant(snap.key, snap.val()));
  });

  onChildRemovedByPath(participantsPath, (snap) => {
    dispatch(removeParticipant(snap.key));
  });

  return dispatch(subscribeToParticipantsSuccess());
};

export const subscribeToObjectsAsync = (meetingId) => async (dispatch) => {
  try {
    dispatch(subscribeToObjectsStart());
    const objectsPath = `meetings/${meetingId}/objects`;

    onChildAddedByPath(objectsPath, (snap) => {
      dispatch(addNewObject(snap.key, snap.val()));
    });

    onChildChangedByPath(objectsPath, (snap) => {
      dispatch(updateObject(snap.key, snap.val()));
    });

    onChildRemovedByPath(objectsPath, (snap) => {
      dispatch(removeObject(snap.key));
    });

    dispatch(subscribeToObjectsSuccess());
  } catch (error) {
    console.log(error.stack);
    // dispatch(subscribeToObjectsFailure());
  }
};

export const addParticipantAsync =
  (meetingId, currentUserKey, mainStream, newParticipantKey, newParticipantValue) =>
  async (dispatch) => {
    try {
      if (currentUserKey === newParticipantKey) {
        newParticipantValue.currentUser = true;
      } else if (mainStream) {
        await addConnection(
          meetingId,
          currentUserKey,
          mainStream,
          newParticipantKey,
          newParticipantValue
        ); // Modifies newParticipantData
      }
      dispatch(addParticipant(newParticipantKey, newParticipantValue));
    } catch (error) {
      console.log(error.stack);
      // dispatch(addParticipantFailure(error.message));
    }
  };

const createAnswer = async (meetingId, otherUserId, userId) => {
  const otherUserPath = `meetings/${meetingId}/participants/${otherUserId}`;

  const pc = store.getState().meeting.participants.byId[otherUserId].peerConnection;
  pc.onicecandidate = (event) => {
    if (event.candidate) {
      const otherUserAnswerCandidatesPath = `${otherUserPath}/answerCandidates`;
      pushAndSetByPath(otherUserAnswerCandidatesPath, {
        ...event.candidate.toJSON(),
        userId
      });
    }
  };

  const answerDescription = await pc.createAnswer();
  await pc.setLocalDescription(answerDescription);

  const answer = {
    type: answerDescription.type,
    sdp: answerDescription.sdp,
    userId
  };

  const otherUserAnswersPath = `${otherUserPath}/answers`;
  await pushAndSetByPath(otherUserAnswersPath, answer);
};

const addConnection = async (
  meetingId,
  currentUserKey,
  stream,
  newParticipantKey,
  newParticipantData
) => {
  const peerConnection = new RTCPeerConnection(SERVERS);
  stream.getTracks().forEach((track) => {
    peerConnection.addTrack(track, stream);
  });

  newParticipantData.peerConnection = peerConnection;
  await createOffer(meetingId, peerConnection, newParticipantKey, currentUserKey);
};

const createOffer = async (meetingId, peerConnection, receiverId, createdID) => {
  const participantsPath = `meetings/${meetingId}/participants`;

  const currentParticipantPath = `${participantsPath}/${receiverId}`;

  peerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      const offerCandidatesPath = `${currentParticipantPath}/offerCandidates`;

      pushByPath(offerCandidatesPath, {
        ...event.candidate.toJSON(),
        userId: createdID
      });
    }
  };

  // const dataChannel = peerConnection.createDataChannel("chat");
  // dataChannel.onmessage = function (event) {
  //   console.log(event);
  // };

  const offerDescription = await peerConnection.createOffer();
  await peerConnection.setLocalDescription(offerDescription);

  const offer = {
    sdp: offerDescription.sdp,
    type: offerDescription.type,
    userId: createdID
  };
  const offersPath = `${currentParticipantPath}/offers`;
  await pushAndSetByPath(offersPath, offer);
};

export const updateRTCPreferencesAsync =
  (meetingId, userId, newPreferences) => async (dispatch) => {
    try {
      const preferecesPath = `/meetings/${meetingId}/participants/${userId}/rtc/`;
      const updates = {};
      for (const [key, val] of Object.entries(newPreferences)) {
        updates[preferecesPath + key] = val;
      }
      await updateByPath(updates);
      dispatch(updateRTCPreferencesSuccess(meetingId, userId, newPreferences));
    } catch (err) {
      console.log(err);
    }
  };

export const subscribeToMessagesAsync = (meetingId) => async (dispatch) => {
  try {
    dispatch(subscribeToMessagesStart());
    const path = `messages/${meetingId}`;

    onChildAddedByPath(path, (snap) => {
      console.log(snap.key, snap.val());
      dispatch(addNewMessage(snap.key, snap.val()));
    });

    dispatch(subscribeToMessagesSuccess());
  } catch (error) {
    console.log(error.stack);
    // dispatch(subscribeToMessagesFailure());
  }
};

export const sendNewMessageAsync = (meetingId, messageObj) => async (dispatch) => {
  try {
    dispatch(sendNewMessageStart());
    const path = `messages/${meetingId}`;
    const messageId = await pushAndSetByPath(path, messageObj);

    dispatch(sendNewMessageSuccess(messageId));
  } catch (error) {
    console.log(error.stack);
    dispatch(sendNewMessageFailure());
  }
};

import { TextureLoader, NearestFilter, LinearMipMapLinearFilter } from 'three';

const dirtURL =
  'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/textures%2Fcubes%2Fdirt.jpg?alt=media&token=556b0175-6fbe-4a7d-b830-5283255862be';
const grassURL =
  'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/textures%2Fcubes%2Fgrass.jpg?alt=media&token=1d585de6-123f-494e-91c2-2990f573f059';
const glassURL =
  'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/textures%2Fcubes%2Fglass.png?alt=media&token=89f4801d-5f6f-4692-8fee-541a3f0656aa';
const logURL =
  'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/textures%2Fcubes%2Flog.jpg?alt=media&token=c76f3b9a-3b65-4166-b156-02ba298b3aa5';
const woodURL =
  'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/textures%2Fcubes%2Fwood.png?alt=media&token=e38d06c0-10c2-42a6-8499-1fefb8e47d6b';
export const dirt = new TextureLoader().load(dirtURL);
export const grass = new TextureLoader().load(grassURL);
export const glass = new TextureLoader().load(glassURL);
export const wood = new TextureLoader().load(woodURL);
export const log = new TextureLoader().load(logURL);

// if nearestFilter is used on minFilter, add:
// texture.generateMitmaps = false;
// to increase the performance

dirt.magFilter = NearestFilter;
dirt.minFilter = LinearMipMapLinearFilter;
grass.magFilter = NearestFilter;
grass.minFilter = LinearMipMapLinearFilter;
glass.magFilter = NearestFilter;
glass.minFilter = LinearMipMapLinearFilter;
wood.magFilter = NearestFilter;
wood.minFilter = LinearMipMapLinearFilter;
log.magFilter = NearestFilter;
log.minFilter = LinearMipMapLinearFilter;

import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useField, useFormikContext } from 'formik';
import { useState } from 'react';

import { classNames } from '../../../utils';

export default function Cards({ label, name, options, onChildClick }) {
  const [selectedCard, setSelectedCard] = useState(options ? options[0] : {});
  const [field, meta, helpers] = useField(name);
  console.log(field, meta, helpers);

  return (
    <RadioGroup value={selectedCard} onChange={setSelectedCard}>
      <RadioGroup.Label className='text-base font-medium text-gray-900'>{label}</RadioGroup.Label>
      <div className='mt-4 grid grid-cols-2 gap-y-6 sm:gap-x-6'>
        {options &&
          options.map((option) => (
            <RadioGroup.Option
              onClick={() => onChildClick(option.title)}
              key={option.name}
              value={option}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                  'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                )
              }>
              {({ checked, active }) => (
                <>
                  <div className='flex-1 flex '>
                    <div className='flex flex-col '>
                      <RadioGroup.Label
                        as='span'
                        className='block text-sm font-medium text-gray-900'>
                        {option.title}
                      </RadioGroup.Label>
                      {option.image && (
                        <img
                          alt=''
                          className='w-32 h-32 object-center object-cover lg:w-32 lg:h-32'
                          src={option.image}
                        />
                      )}
                      <RadioGroup.Description
                        as='span'
                        className='mt-1 flex items-center text-sm text-gray-500'>
                        {option.description}
                      </RadioGroup.Description>
                    </div>
                  </div>
                  <CheckCircleIcon
                    className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                    aria-hidden='true'
                  />
                  <div
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg pointer-events-none'
                    )}
                    aria-hidden='true'
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
      </div>
    </RadioGroup>
  );
}

import { OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { useFormikContext } from 'formik';
import { Suspense, useRef } from 'react';

function Avatar({ color, ...props }) {
  const avatarUrl =
    'https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/avatars%2Fcubeman.glb?alt=media&token=d3531876-d9f8-4452-bb8e-2e59189c6cf5';
  const { nodes } = useGLTF(avatarUrl);
  const modelRef = useRef(null);
  const group = useRef();

  return (
    <group ref={group}>
      <group {...props} dispose={null}>
        <group ref={modelRef} rotation={[0, 0, 0]} position={[0, -3.75, 0]} scale={1.5}>
          <mesh geometry={nodes.Cube.geometry}>
            <meshStandardMaterial color={color} />
          </mesh>
        </group>
      </group>
    </group>
  );
}

export default function AvatarPreview(props) {
  const { values } = useFormikContext();

  return (
    <Suspense fallback={null}>
      <Canvas>
        <ambientLight intensity={0.4} />
        <pointLight position={[10, 5, 10]} color='white' />
        <Avatar {...props} color={values.skin.color} />
        <OrbitControls
          enableDamping
          dampingFactor={0.05}
          enableZoom
          minDistance={3}
          maxDistance={7}
          enablePan={false}
          maxPolarAngle={Math.PI / 2 - 0.05}
        />
      </Canvas>
    </Suspense>
  );
}

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const SIGN_IN_ANON_START = 'SIGN_IN_ANON_START';
export const SIGN_IN_ANON_SUCCESS = 'SIGN_IN_ANON_SUCCESS';
export const SIGN_IN_ANON_FAILURE = 'SIGN_IN_ANON_FAILURE';

export const SIGN_IN_WITH_GOOGLE_START = 'SIGN_IN_WITH_GOOGLE_START';
export const SIGN_IN_WITH_GOOGLE_SUCCESS = 'SIGN_IN_WITH_GOOGLE_SUCCESS';
export const SIGN_IN_WITH_GOOGLE_FAILURE = 'SIGN_IN_WITH_GOOGLE_FAILURE';

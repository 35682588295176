import React, { useEffect, useRef } from 'react';
import './Participants.css';
import Participant from './Participant';

function Participants({ participants, currentUser, stream }) {
  const videoRef = useRef(null);
  const participantKeys = Object.keys(participants);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.muted = !!(currentUser && !currentUser.audio);
    }
  }, [currentUser, stream]);

  const screenPresenter = participantKeys.find((element) => {
    const currentParticipant = participants[element];
    return currentParticipant.screen;
  });

  const renderParticipants = participantKeys.map((element) => {
    const currentParticipant = participants[element];
    const isCurrentUser = currentUser.uid === element;

    if (isCurrentUser) {
      return null;
    }

    const pc = currentParticipant.peerConnection;
    if (pc) {
      const remoteStream = new MediaStream();

      pc.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });
        const videElement = document.getElementById(`participantVideo-${element}`);
        if (videElement) videElement.srcObject = remoteStream;
      };

      // const dataChannel = pc.createDataChannel("chat", {
      //   reliable: true,
      //   ordered: true,
      // });
      // dataChannel.onmessage = function (event) {
      //   console.log(event, event.data);
      // };
    }

    return (
      <Participant
        key={element}
        currentParticipant={currentParticipant}
        hideVideo={screenPresenter && screenPresenter !== element}
        showAvatar={
          !currentParticipant.video && !currentParticipant.screen && currentParticipant.name
        }
        uid={element}
      />
    );
  });

  return (
    <div className='participants'>
      {renderParticipants}
      <Participant
        key={currentUser.uid}
        currentParticipant={currentUser}
        hideVideo={screenPresenter && !currentUser.screen}
        isCurrentUser
        showAvatar={currentUser && !currentUser.video && !currentUser.screen}
        uid={currentUser.uid}
        videoRef={videoRef}
      />
    </div>
  );
}

export default Participants;

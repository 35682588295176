import {
  PencilAltIcon,
  PhotographIcon,
  PlayIcon,
  PuzzleIcon,
  SearchIcon
} from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';

const items = [
  { name: '3D environment', key: 'canvas', icon: PhotographIcon },
  { name: '360° Video', key: 'video360', icon: PlayIcon },
  { name: 'Game', key: 'game', icon: PuzzleIcon },
  { name: 'Whiteboard', key: 'whiteboard', icon: PencilAltIcon },
  { name: 'Search', key: 'search', icon: SearchIcon }
  // { name: 'Editor', key: 'editor' }
];

export default function ControlPlane({ setActivityType, setShowSearchBar }) {
  const renderButtonWithIcon = (item) => {
    const IconComponentName = item.icon;
    return (
      <li className='mt-2'>
        <button
          data-tip={item.name}
          data-place='left'
          onClick={() =>
            item.key === 'search' ? setShowSearchBar(true) : setActivityType(item.key)
          }
          type='button'
          className='inline-flex ml-auto items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-black hover:bg-indigo-700 focus:outline-none focus:ring-4 focus:ring-offset-2 focus:ring-indigo-500'>
          <IconComponentName className='h-10 w-10' aria-hidden='true' />
        </button>
        <ReactTooltip />
      </li>
    );
  };

  return (
    <div style={{ position: 'absolute', right: '1rem', top: '1rem' }}>
      <div className='relative z-0 flex justify-end	shadow-sm rounded-md'>
        <ul>{items.map(renderButtonWithIcon)}</ul>
      </div>
    </div>
  );
}

import {
  FETCH_MEETING_DATA_START,
  FETCH_MEETING_DATA_SUCCESS,
  FETCH_MEETING_DATA_FAILURE,
  CREATE_MEETING_START,
  CREATE_MEETING_SUCCESS,
  CREATE_MEETING_FAILURE,
  CREATE_NEW_OBJECT_START,
  CREATE_NEW_OBJECT_SUCCESS,
  CREATE_NEW_OBJECT_FAILURE,
  SUBSCRIBE_TO_PARTICIPANTS_START,
  SUBSCRIBE_TO_PARTICIPANTS_SUCCESS,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT,
  UPDATE_PARTICIPANT,
  SUBSCRIBE_TO_OBJECTS_START,
  SUBSCRIBE_TO_OBJECTS_SUCCESS,
  UPDATE_RTC_PREFERENCES,
  ADD_OBJECT,
  REMOVE_OBJECT,
  UPDATE_OBJECT,
  SUBSCRIBE_TO_MESSAGES_START,
  SUBSCRIBE_TO_MESSAGES_SUCCESS,
  ADD_MESSAGE,
  SEND_NEW_MESSAGE_START,
  SEND_NEW_MESSAGE_FAILURE,
  SEND_NEW_MESSAGE_SUCCESS
} from './types';

const DEFAULT_STATE = {
  get: { data: null, isLoading: false, error: null },
  create: { newMeetingId: '', isSubmitting: false, error: null },
  createNewObject: { isSubmitting: false, error: null, success: false },
  participants: { byId: {}, allIds: [], hasSubscribed: false, isSubscribing: false },
  objects: { byId: {}, allIds: [], hasSubscribed: false, isSubscribing: false },
  messages: {
    all: [],
    count: 0,
    hasSubscribed: false,
    isSubscribing: false,
    isSending: false,
    sendingError: null
  }
};

// eslint-disable-next-line no-use-before-define
const deleteProperty = ({ [key]: _, ...newObj }, key) => newObj;

const meetingReducer = (state = DEFAULT_STATE, action) => {
  const { payload, type } = action;
  // console.log({ ...action, state });
  switch (type) {
    case FETCH_MEETING_DATA_START: {
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null
        }
      };
    }
    case FETCH_MEETING_DATA_SUCCESS: {
      return {
        ...state,
        get: {
          ...state.get,
          data: {
            id: payload.id,
            ...payload.meeting
          },
          isLoading: false,
          error: null
        }
        // objects: {
        //   byId: { ...payload.meeting.objects },
        //   allIds: Object.keys(payload.meeting.objects || {}),
        // },
        // participants: {
        //   byId: { ...payload.meeting.participants },
        //   allIds: Object.keys(payload.meeting.participants || {}),
        // },
      };
    }
    case FETCH_MEETING_DATA_FAILURE: {
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: payload.error
        }
      };
    }
    case CREATE_MEETING_START: {
      return {
        ...state,
        create: {
          ...state.create,
          isSubmitting: true,
          error: null
        }
      };
    }
    case CREATE_MEETING_SUCCESS: {
      return {
        ...state,
        create: {
          ...state.create,
          newMeetingId: payload.meetingId,
          isSubmitting: false,
          error: null
        }
      };
    }
    case CREATE_MEETING_FAILURE: {
      return {
        ...state,
        create: {
          ...state.create,
          isSubmitting: false,
          error: payload.error
        }
      };
    }
    case CREATE_NEW_OBJECT_START: {
      return {
        ...state,
        createNewObject: {
          ...state.createNewObject,
          isSubmitting: true
        }
      };
    }
    case CREATE_NEW_OBJECT_SUCCESS: {
      return {
        ...state,
        objects: {
          ...state.objects,
          byId: {
            ...state.objects.byId,
            [payload.objectKey]: payload.objectValue
          },
          allIds: [...state.objects.allIds, payload.objectKey]
        },
        createNewObject: {
          ...state.createNewObject,
          isSubmitting: false,
          error: null,
          success: true
        }
      };
    }
    case CREATE_NEW_OBJECT_FAILURE: {
      return {
        ...state,
        createNewObject: {
          ...state.createNewObject,
          isSubmitting: false,
          error: payload.error,
          success: false
        }
      };
    }

    // CHAT
    case SUBSCRIBE_TO_MESSAGES_START:
      return {
        ...state,
        messages: {
          ...state.messages,
          isSubscribing: true
        }
      };
    case SUBSCRIBE_TO_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          hasSubscribed: true
        }
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          all: [...state.messages.all, payload.value]
        }
      };
    case SEND_NEW_MESSAGE_START:
      return {
        ...state,
        messages: {
          ...state.messages,
          isSending: true
        }
      };
    case SEND_NEW_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          isSending: false
        }
      };
    case SEND_NEW_MESSAGE_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          isSending: false,
          sendingError: payload.errorMessage
        }
      };

    // RTC
    case SUBSCRIBE_TO_PARTICIPANTS_START:
      return {
        ...state,
        participants: {
          ...state.participants,
          isSubscribing: true
        }
      };
    case SUBSCRIBE_TO_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: {
          ...state.participants,
          isSubscribing: false,
          hasSubscribed: true
        }
      };
    case ADD_PARTICIPANT:
      return {
        ...state,
        participants: {
          ...state.participants,
          byId: {
            ...state.participants.byId,
            [payload.participantKey]: payload.participantValue
          },
          allIds: [...state.participants.allIds, payload.participantKey]
        }
      };

    case REMOVE_PARTICIPANT:
      return {
        ...state,
        participants: {
          ...state.participants,
          byId: deleteProperty(state.participants.byId, payload.participantKey),
          allIds: state.participants.allIds.filter((id) => id !== payload.participantKey)
        }
      };

    case UPDATE_PARTICIPANT:
      return {
        ...state,
        participants: {
          ...state.participants,
          byId: {
            ...state.participants.byId,
            [payload.participantKey]: {
              ...state.participants.byId[payload.participantKey],
              ...payload.overrideData
            }
          }
        }
      };

    case SUBSCRIBE_TO_OBJECTS_START:
      return {
        ...state,
        objects: {
          ...state.objects,
          isSubscribing: true
        }
      };
    case SUBSCRIBE_TO_OBJECTS_SUCCESS:
      return {
        ...state,
        objects: {
          ...state.objects,
          isSubscribing: false,
          hasSubscribed: true
        }
      };
    case ADD_OBJECT:
      return {
        ...state,
        objects: {
          ...state.objects,
          byId: {
            ...state.objects.byId,
            [payload.objectKey]: payload.objectValue
          },
          allIds: [...state.objects.allIds, payload.objectKey]
        }
      };

    case REMOVE_OBJECT:
      return {
        ...state,
        objects: {
          ...state.objects,
          byId: deleteProperty(state.objects.byId, payload.objectKey),
          allIds: state.objects.allIds.filter((id) => id !== payload.objectKey)
        }
      };

    case UPDATE_OBJECT:
      return {
        ...state,
        objects: {
          ...state.objects,
          byId: {
            ...state.objects.byId,
            [payload.objectKey]: {
              ...state.objects.byId[payload.objectKey],
              ...payload.overrideData
            }
          }
        }
      };
    case UPDATE_RTC_PREFERENCES:
      return {
        ...state,
        participants: {
          ...state.participants,
          byId: {
            ...state.participants.byId,
            [payload.userId]: {
              ...state.participants.byId[payload.userId],
              rtc: {
                ...state.participants.byId[payload.userId].rtc,
                ...payload.newPreferences
              }
            }
          }
        }
      };
    default:
      return state;
  }
};

export default meetingReducer;

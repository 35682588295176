import { useField } from 'formik';

import Input from '../design-system/form/Input';

export default function InputFormik({
  label,
  name,
  type = 'text',
  className = 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
  // labelClassName = 'block text-sm font-medium text-gray-700',
  ...props
}) {
  const [field, meta] = useField(name);

  return (
    <Input
      id={name}
      label={label}
      type={type}
      className={className}
      errorMessage={meta.touched && meta.error ? meta.error : ''}
      {...field}
      {...props}
    />
  );
}

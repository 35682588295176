import Frames from './Frames';
// import Wall from './Wall';
import {
  Cube,
  Cylinder,
  Plane,
  Sphere,
  Submarine,
  Torus,
  Triangle,
  Monkey,
  Wall,
  Frame
} from './object';

export default function Objects({ baseObjectData, sceneObjects, addObject, removeObject }) {
  const hasClickActions = !!(addObject && removeObject);
  const handleObjectClick = (evt, ref, objectKey) => {
    evt.stopPropagation();
    // TODO: figure out where faceindex comes from
    const clickedFace = Math.floor(evt.faceIndex / 2);
    const { x, y, z } = ref.current.position;
    if (clickedFace === 0) {
      if (evt.altKey) removeObject(objectKey);
      else addObject(x + 1, y, z);
      return;
    }
    if (clickedFace === 1) {
      if (evt.altKey) removeObject(objectKey);
      else addObject(x - 1, y, z);
      return;
    }
    if (clickedFace === 2) {
      if (evt.altKey) removeObject(objectKey);
      else addObject(x, y + 1, z);
      return;
    }
    if (clickedFace === 3) {
      if (evt.altKey) removeObject(objectKey);
      else addObject(x, y - 1, z);
      return;
    }
    if (clickedFace === 4) {
      if (evt.altKey) removeObject(objectKey);
      else addObject(x, y, z + 1);
      return;
    }
    if (clickedFace === 5) {
      if (evt.altKey) removeObject(objectKey);
      else addObject(x, y, z - 1);
    }
  };

  const renderObject = (objectKey, objectValue) => {
    const nameToComponent = {
      cube: Cube,
      sphere: Sphere,
      torus: Torus,
      submarine: Submarine,
      triangle: Triangle,
      // frame: Frames,
      plane: Plane,
      cylinder: Cylinder,
      monkey: Monkey,
      wall: Wall,
      frame: Frame
    };
    const Component = nameToComponent[objectValue.name];

    if (!Component) {
      return null;
    }

    return (
      <Component
        key={objectKey}
        position={objectValue.position}
        handleClick={(evt, ref) => hasClickActions && handleObjectClick(evt, ref, objectKey)}
        {...baseObjectData[objectValue.name]}
        {...objectValue}
      />
    );
  };

  return <>{Object.entries(sceneObjects).map(([key, value]) => renderObject(key, value))}</>;
}

import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInAnonymously
  // signInWithPopup
} from 'firebase/auth';
import firebaseApp from './init';

export const auth = getAuth(firebaseApp);

export const signInAnon = async () => {
  try {
    const data = await signInAnonymously(auth);
    return data;
  } catch (err) {
    // const errorCode = err.code;
    // const errorMessage = err.message;
    return undefined;
    // console.log(errorCode, errorMessage);
  }
};

export const onAuthChange = () => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
    }
  });
};

export const googleProvider = new GoogleAuthProvider();

import { useState } from 'react';

import Layout from '../../components/Layout';
import { FormikContainer } from '../../components/form';
import ControlPlane from './ControlPlane';
import ObjectTree from './ObjectTree';
import Scene from './Scene';
import TemplateSelector from './TemplateSelector';

export default function Editor() {
  const INITIAL_STATE = {
    main: {
      title: 'Main',
      camera: { position: [15, 2, 7] },
      name: '',
      transform: { position: [0, 0, 0] },
      children: []
    }
  };

  const [initialFormValues, setInitialFormValues] = useState(INITIAL_STATE);
  const [selectedItem, setSelectedItem] = useState('main');
  const [isTemplateSelectorOpen, setIsTemplateSelectorOpen] = useState(true);
  const handleSubmit = () => {};

  return (
    <Layout>
      <FormikContainer
        className='h-full lg:grid lg:grid-cols-12'
        enableReinitialize
        stringifiedValues={JSON.stringify(initialFormValues)}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}>
        <TemplateSelector
          open={isTemplateSelectorOpen}
          setOpen={setIsTemplateSelectorOpen}
          setInitialFormValues={setInitialFormValues}
        />
        <ObjectTree
          selectedItem={selectedItem}
          setIsTemplateSelectorOpen={setIsTemplateSelectorOpen}
          setSelectedItem={setSelectedItem}
        />
        <Scene
          stringifiedValues={JSON.stringify(initialFormValues)}
          setSelectedItem={setSelectedItem}
        />
        <ControlPlane selectedItem={selectedItem} setSelectedItem={setSelectedItem} />{' '}
      </FormikContainer>
    </Layout>
  );
}

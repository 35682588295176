/* eslint-disable prettier/prettier */
import { useConvexPolyhedron } from '@react-three/cannon';
import { useAnimations, useGLTF } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MathUtils, Vector3 } from 'three';
import * as THREE from 'three';
import { Geometry } from 'three-stdlib';

import { setByPath } from '../../../../firebase/database';
import useKeyboardControls from '../../../../hooks/useKeyboardControls';

const { lerp } = MathUtils;
/**
 * Returns legacy geometry vertices, faces for ConvP
 * @param {THREE.BufferGeometry} bufferGeometry
 */

const SPEED = 6;

function toConvexProps(bufferGeometry) {
    const geo = new Geometry().fromBufferGeometry(bufferGeometry);
    // Merge duplicate vertices resulting from glTF export.
    // Cannon assumes contiguous, closed meshes to work
    geo.mergeVertices();
    return [geo.vertices.map((v) => [v.x, v.y, v.z]), geo.faces.map((f) => [f.a, f.b, f.c]), []]; // prettier-ignore
}

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

function isEqual(vectorA, vectorB) {
    return vectorA.x === vectorB.x && vectorA.y === vectorB.y && vectorA.z === vectorB.z;
}

const ANIMATIONS = {
    Idle: 'Idle',
    Walking: 'Walking'
};

export default function Avatar({
    avatarConfig,
    avatarUrl,
    setPlayerPosition,
    playerPosition,
    setVelocity,
    setDelta,
    setDirection,
    ...props
}) {
    const { speed, meetingId, uid } = props;
    const { camera } = useThree();
    const { moveForward, moveBackward, moveLeft, moveRight, jump } = useKeyboardControls();
    const { nodes, materials, animations } = useGLTF('https://firebasestorage.googleapis.com/v0/b/metavis-ee143.appspot.com/o/avatars%2Fcubeperson.gltf?alt=media&token=5755030b-db62-437a-9e66-05a8060083c0');

    const group = useRef();
    const modelRef = useRef(null);
    const velocity = useRef([0, 0, 0]);
    const direction = new Vector3();

    const { actions } = useAnimations(animations, group);
    const [action, setAction] = useState(ANIMATIONS.Idle);
    const previousAction = usePrevious(action);

    const geo = useMemo(() => toConvexProps(nodes.Cube004.geometry), [nodes]);
    const [ref, api] = useConvexPolyhedron(() => ({
        type: 'Dynamic',
        mass: 0,
        args: geo,
        position: [0, 1, 0],
        speed
    }));

    useEffect(() => {
        if (previousAction) {
            actions[previousAction].fadeOut(0.2);
            actions[action].stop();
        }
        actions[action].play();
        actions[action].fadeIn(0.2);
    }, [actions, action, previousAction]);

    useEffect(() => {
        api.velocity.subscribe((v) => {
            velocity.current = v;
        });
        setByPath(`meetings/${meetingId}/participants/${uid}/three/position`, [
            ref.current.position.x,
            ref.current.position.y,
            ref.current.position.z
        ]);
    }, [meetingId, ref, uid, api.velocity, moveForward, moveBackward, moveLeft, moveRight, jump]);

    useFrame((_, delta) => {
        const rotateQuaternion = new THREE.Quaternion();
        const frontVector = new Vector3(0, 0, (moveBackward ? 1 : 0) - (moveForward ? 1 : 0));
        const sideVector = new Vector3((moveLeft ? 1 : 0) - (moveRight ? 1 : 0), 0, 0);

        direction
            .subVectors(frontVector, sideVector)
            .normalize()
            .multiplyScalar(SPEED)
            .applyEuler(camera.rotation);

        const angleYCameraDirection = Math.atan2(
            camera.position.x - ref.current.position.x,
            camera.position.z - ref.current.position.z
        );

        if (isEqual(direction, new Vector3(0, 0, 0))) {
            setAction(ANIMATIONS.Idle);
        } else {
            setAction(ANIMATIONS.Walking);
            let directionOffset = 0;
            if (moveForward) {
                if (moveLeft) {
                    directionOffset = Math.PI / 4; // w+a
                } else if (moveRight) {
                    directionOffset = -Math.PI / 4; // w+d
                }
            } else if (moveBackward) {
                if (moveLeft) {
                    directionOffset = Math.PI / 4 + Math.PI / 2; // s+a
                } else if (moveRight) {
                    directionOffset = -Math.PI / 4 - Math.PI / 2; // s+d
                } else {
                    directionOffset = Math.PI; // s
                }
            } else if (moveLeft) {
                directionOffset = Math.PI / 2; // a
            } else if (moveRight) {
                directionOffset = -Math.PI / 2; // d
            }

            if (modelRef.current) {
                modelRef.current.rotation.z = lerp(
                    modelRef.current.rotation.z,
                    directionOffset + angleYCameraDirection,
                    delta * 2
                );
            }
        }

        api.velocity.set(direction.x, velocity.current[1], direction.z);

        if (jump && Math.abs(velocity.current[1].toFixed(2)) < 0.05) {
            api.velocity.set(velocity.current[0], 8, velocity.current[2]);
        }
        ref.current.getWorldPosition(ref.current.position);

        // rotateQuaternion.setFromAxisAngle(rotateAngle, angleYCameraDirection + directionOffset);

        ref.current.getWorldQuaternion(rotateQuaternion, 0.2);

        setPlayerPosition(ref.current.position);
        setVelocity(new Vector3(velocity.current[0], 8, velocity.current[2]));
        setDirection(direction);
        setDelta(delta);
    });

    return (
        <group ref={group}>
            <group ref={ref} {...props} dispose={null}>
                <group ref={modelRef} rotation={[Math.PI / 2, 0, 0]}>
                    <group name='Armature' position={[0, 0, 0]} scale={0.01}>
                        <primitive object={nodes.mixamorigHips} />
                        <skinnedMesh
                            name='Cube004'
                            geometry={nodes.Cube004.geometry}
                            color='red'
                            material={nodes.Cube004.material}
                            skeleton={nodes.Cube004.skeleton}
                        />
                        <mesh
                            geometry={nodes.Cube004.geometry}
                            material={nodes.Cube004.material}
                            skeleton={nodes.Cube004.skeleton}>
                            <meshStandardMaterial color='red' transparent opacity={1} />
                        </mesh>
                    </group>
                </group>
            </group>
        </group>
    );
}

import {
  getDatabase,
  ref,
  get,
  set,
  push,
  onValue,
  remove,
  onChildAdded,
  onChildRemoved,
  update,
  child
} from 'firebase/database';
import firebaseApp from './init';

const db = getDatabase(firebaseApp);

const dbRef = ref(db);

export const getRef = (path) => child(dbRef, path);

export const getByPath = async (path) => {
  try {
    const pathRef = getRef(path);
    const snapshot = await get(pathRef);
    if (snapshot.exists()) {
      return snapshot.val();
    }
    throw new Error('Not found');
  } catch (err) {
    return undefined;
    // console.error(err.message);
  }
};

export const deleteByPath = async (path) => {
  try {
    const pathRef = getRef(path);
    remove(pathRef);
  } catch (err) {
    // console.error(err.message);
  }
};

export const pushByPath = async (path, data) => {
  try {
    const pathRef = getRef(path);
    await push(pathRef, data);
  } catch (err) {
    // console.error(err.message);
  }
};

export const setByPath = async (path, data) => {
  try {
    const pathRef = getRef(path);
    await set(pathRef, data);
  } catch (err) {
    // console.error(err.message);
  }
};

export const pushAndSetByPath = async (path, data) => {
  const parentRef = getRef(path);
  const childRef = push(parentRef);
  await set(childRef, data);

  return childRef.key;
};

export const updateByPath = async (path, updates) => {
  try {
    await update(dbRef, updates);
  } catch (err) {
    // console.error(err.message);
  }
};

export const onChildAddedByPath = (path, callback) => {
  const pathRef = getRef(path);

  onChildAdded(pathRef, (snapshot) => {
    callback(snapshot);
  });
};

export const onChildChangedByPath = (path, callback) => {
  const pathRef = getRef(path);

  onChildRemoved(pathRef, (snapshot) => {
    callback(snapshot);
  });
};

export const onChildRemovedByPath = (path, callback) => {
  const pathRef = getRef(path);

  onChildRemoved(pathRef, (snapshot) => {
    callback(snapshot);
  });
};

export const subscribeToPath = (path, callback, argsObj) => {
  const pathRef = getRef(path);

  onValue(pathRef, (snapshot) => {
    const data = snapshot.val();
    if (data) {
      callback(data, argsObj);
    }
  });
};

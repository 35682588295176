import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createMeetingAsync } from '../redux/meeting/thunks';

import Layout from '../components/Layout';
import {
  FormikContainer,
  ColorPicker,
  Input,
  Select,
  SubmitButton,
  RadioButton,
  Steps
} from '../components/form';
import ScenePreview from '../components/preview/ScenePreview';
import AvatarPreview from '../components/preview/AvatarPreview';
import InviteOthers from '../components/preview/InviteOthers';

const INITIAL_VALUES = {
  sceneName: '',
  sceneType: 'minecraft'
};

function CreateMeeting() {
  const dispatch = useDispatch();
  const { isSubmitting, newMeetingId, error } = useSelector((st) => st.meeting.create);

  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    // Succesfully created a new meeting
    if (newMeetingId) {
      navigate(`/meeting/${newMeetingId}`);
    }
  });

  const handlePrev = () => {
    setCurrentStep((curr) => curr - 1);
  };

  const handleNext = () => {
    setCurrentStep((curr) => curr + 1);
  };

  const onSubmit = (values) => {
    dispatch(createMeetingAsync(values));
  };

  const steps = [
    {
      id: 'scene',
      label: '01',
      name: 'Environment',
      href: '#',
      status: 'complete'
    },
    { id: 'avatar', label: '02', name: 'Avatar', href: '#', status: 'current' },
    {
      id: 'participants',
      label: '03',
      name: 'Participants',
      href: '#',
      status: 'upcoming'
    }
  ];

  const textureMatOptions = [
    { id: 'dirt.jpg', name: 'Dirt' },
    { id: 'glass.jpg', name: 'Glass' },
    { id: 'grass.jpg', name: 'Grass' },
    { id: 'log.jpg', name: 'Log' },
    { id: 'wood.jpg', name: 'Wood' }
  ];

  const sceneOptions = [
    { id: 'minecraft', name: 'Minecraft' },
    { id: 'gallery', name: 'Gallery' }
  ];

  if (isSubmitting) {
    return <div>Creating...</div>;
  }

  // Error occured when creating a meeting
  if (error) {
    return <div>An error occured</div>;
  }

  const isLastStep = currentStep === steps.length;

  const renderFormStep = (step) => {
    switch (step) {
      case 1:
        return (
          <>
            <RadioButton label='Scene type' options={sceneOptions} name='sceneType' />
            <Input label='Scene name' name='sceneName' />
            <Select
              label='Select a type'
              name='ground.meshMaterial.textureMat'
              options={textureMatOptions}
            />
            <ColorPicker
              label='Pick a hover color'
              name='baseObjectData.cube.meshStandardMaterial.hoverColor'
            />
          </>
        );
      case 2:
        return 'Avatar settings';
      case 3:
        return 'Invite others';
      default:
        return 'Invalid form step';
    }
  };

  const renderPreview = (step) => {
    switch (step) {
      case 1:
        return <ScenePreview />;
      case 2:
        return (
          <Suspense fallback={null}>
            <AvatarPreview />
          </Suspense>
        );
      case 3:
        return <InviteOthers />;
      default:
        return '';
    }
  };

  return (
    <Layout>
      <Steps steps={steps} currentStep={currentStep} />

      <FormikContainer initialValues={INITIAL_VALUES} onSubmit={onSubmit} className='flex'>
        {/* Form step starts */}
        <div className='w-1/2'>
          <div className='mt-10 px-8'>
            {renderFormStep(currentStep)}

            <div className='pt-5'>
              <div className='flex justify-end'>
                {currentStep > 1 && (
                  <button
                    type='button'
                    onClick={handlePrev}
                    className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                    Back
                  </button>
                )}
                {isLastStep ? (
                  <SubmitButton label='Create New Meeting' />
                ) : (
                  <button
                    type='button'
                    onClick={handleNext}
                    className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                    {isLastStep ? 'Create room' : 'Next'}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Form step ends */}

        {/* Preview starts */}
        <div className='w-1/2' style={{ minHeight: '50vh' }}>
          {renderPreview(currentStep)}
        </div>
        {/* Preview ends */}
      </FormikContainer>
    </Layout>
  );
}

export default CreateMeeting;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { signInWithGoogle } from '../redux/auth/thunks';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const user = useSelector((st) => st.auth.user.data);

  useEffect(() => {
    // If user has already logged in, navigate to dashboard
    if (user && !user.isAnonymous) {
      navigate(state?.from || '/dashboard');
    }
  }, [navigate, state?.from, user]);

  function handleSignInWithGoogle() {
    dispatch(signInWithGoogle());
  }

  return (
    <div className='h-full w-full py-16 px-4'>
      <div className='flex flex-col items-center justify-center'>
        <div className='bg-white shadow rounded lg:w-1/3  md:w-1/2 w-full p-10 mt-16'>
          <p className='focus:outline-none text-2xl font-extrabold leading-6 text-gray-800'>
            Login to your account
          </p>
          <p className='focus:outline-none text-sm mt-4 font-medium leading-none text-gray-500'>
            Dont have account?{' '}
            <a
              href='/register'
              className='hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer'>
              Sign up here
            </a>
          </p>
          <button
            type='button'
            onClick={handleSignInWithGoogle}
            aria-label='Continue with google'
            className='focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-700 flex items-center w-full mt-10'>
            <img
              src='https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg2.svg'
              alt='google'
            />
            <p className='text-base font-medium ml-4 text-gray-700'>Continue with Google</p>
          </button>
          <button
            type='button'
            aria-label='Continue with github'
            className='focus:outline-none  focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-700 flex items-center w-full mt-4'>
            <img
              src='https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg3.svg'
              alt='github'
            />
            <p className='text-base font-medium ml-4 text-gray-700'>Continue with Github</p>
          </button>
          <button
            type='button'
            aria-label='Continue with twitter'
            className='focus:outline-none  focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-700 flex items-center w-full mt-4'>
            <img
              src='https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg4.svg'
              alt='twitter'
            />
            <p className='text-base font-medium ml-4 text-gray-700'>Continue with Twitter</p>
          </button>
          <div className='w-full flex items-center justify-between py-5'>
            <hr className='w-full bg-gray-400' />
            <p className='text-base font-medium leading-4 px-2.5 text-gray-400'>OR</p>
            <hr className='w-full bg-gray-400  ' />
          </div>
          <div>
            <label htmlFor='email' className='text-sm font-medium leading-none text-gray-800'>
              Email
            </label>
            <input
              id='email'
              aria-labelledby='email'
              type='email'
              className='bg-gray-200 border rounded  text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2'
            />
          </div>
          <div className='mt-6  w-full'>
            <label htmlFor='password' className='text-sm font-medium leading-none text-gray-800'>
              Password
            </label>
            <input
              id='password'
              type='password'
              className='bg-gray-200 border rounded  text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2'
            />
            <div className='absolute right-0 mt-2 mr-3 cursor-pointer'>
              <img
                src='https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg5.svg'
                alt='viewport'
              />
            </div>
          </div>
          <div className='mt-8'>
            <button
              type='button'
              className='focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded hover:bg-indigo-600 py-4 w-full'>
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import './Participant.css';

function Participant(props) {
  // console.log("Participant -> ", props);
  const { currentParticipant, hideVideo, isCurrentUser, videoRef, uid } = props;

  return (
    <div className={`participant ${hideVideo ? 'hide' : ''}`}>
      <video ref={videoRef} className='video' id={`participantVideo-${uid}`} autoPlay playsInline />
      {!currentParticipant.audio && (
        <FontAwesomeIcon className='muted' icon={faMicrophoneSlash} title='Muted' />
      )}
      <div className='name'>
        {currentParticipant.name}
        {isCurrentUser ? '(You)' : ''}
      </div>
    </div>
  );
}

export default Participant;

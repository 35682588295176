export default function Checkbox({ label, name, description, ...props }) {
  return (
    <div className='relative flex items-start'>
      <div className='flex items-center h-5'>
        <input
          id={description ? '' : name}
          aria-describedby={name}
          name={name}
          type='checkbox'
          className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
          {...props}
        />
      </div>
      <div className='ml-3 text-sm'>
        <label htmlFor={name} className='font-medium text-gray-700'>
          {label}
        </label>
        {description ? (
          <p id={name} className='text-gray-500'>
            {description}
          </p>
        ) : null}
      </div>
    </div>
  );
}

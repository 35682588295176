import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from "react-redux";

import Layout from '../components/Layout';

export default function Dashboard() {
  //   const dispatch = useDispatch();
  useEffect(() => {
    function loadUsersRooms() {}
    loadUsersRooms();
  });
  return <Layout>Dashboard</Layout>;
}

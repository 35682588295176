import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faVideo,
  faDesktop,
  faVideoSlash,
  faMicrophoneSlash
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import './MeetingFooter.css';

function MeetingFooter({
  onMicClick,
  onScreenClick,
  onVideoClick,
  currentParticipantPreferences: { audio, video, screen }
}) {
  const handleMicClick = () => {
    onMicClick(!audio);
  };

  const handleVideoClick = () => {
    onVideoClick(!video);
  };

  const handleScreenClick = () => {
    onScreenClick(!screen);
  };

  return (
    <div className='meeting-footer'>
      <div
        className={`meeting-icons ${audio ? 'active' : ''}`}
        data-tip={audio ? 'Mute Audio' : 'Unmute Audio'}
        onClick={handleMicClick}>
        <FontAwesomeIcon size='lg' icon={!audio ? faMicrophoneSlash : faMicrophone} title='Mute' />
      </div>
      <div
        className={`meeting-icons ${video ? 'active' : ''}`}
        data-tip={video ? 'Hide Video' : 'Show Video'}
        onClick={handleVideoClick}>
        <FontAwesomeIcon size='lg' icon={!video ? faVideoSlash : faVideo} />
      </div>
      <div
        className={`meeting-icons ${screen ? 'active' : ''}`}
        data-tip={screen ? 'Stop sharing' : 'Share screen'}
        onClick={handleScreenClick}>
        <FontAwesomeIcon size='lg' icon={faDesktop} />
      </div>
      <ReactTooltip />
    </div>
  );
}

export default MeetingFooter;

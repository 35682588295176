import React from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { OrthographicCamera } from '@react-three/drei';

const boxHeight = 1;
let lastTime = 0;
let time = 10;

function Camera({ stackL }) {
  // Camera Dolly
  const { camera } = useThree();
  const x = 4;
  const y = 4;
  const z = 4;
  camera.position.set(x, y, z);
  // state.camera.position.lerp(vec.set(x, y, z), step);
  camera.lookAt(0, 0, 0);

  useFrame(() => {
    if (lastTime) {
      // const timePassed = time - lastTime;
      // const speed = 0.5;
      if (camera.position.y < boxHeight * (stackL - 2) + 4) {
        // camera.position.y += speed * timePassed;
        camera.position.y += 1;
      }

      // console.log(camera.position);
      camera.updateProjectionMatrix();
    }
    time += 10;
    lastTime = time;

    // console.log(state.camera.position);
  });

  // Camera
  const sizeObj = useThree(({ size }) => size);

  return (
    <OrthographicCamera
      makeDefault
      zoom={100}
      left={sizeObj.width / -1}
      right={sizeObj.width / 1}
      top={sizeObj.height / 1}
      bottom={sizeObj.height / -1}
    />
  );
}

export default Camera;

import { combineReducers } from 'redux';
import meeting from './meeting/reducer';
import rtc from './rtc/reducer';
import auth from './auth/reducer';
import base from './base/reducer';

export default combineReducers({
  meeting,
  base,
  rtc,
  auth
});

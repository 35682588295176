import Input from './Input';

export default function InputMultiple({ label, name }) {
  return (
    <>
      <h6>{label}</h6>
      <div className='grid grid-cols-3 gap-2'>
        <Input type='number' label='X' name={`${name}[0]`} />
        <Input type='number' label='Y' name={`${name}[1]`} />
        <Input type='number' label='Z' name={`${name}[2]`} />
      </div>
    </>
  );
}
